import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import { InputDropDown } from "../../../../components/Inputs/InputDropDown";
import { InputRadio } from "../../../../components/Inputs/InputRadio";
import NewPhotoButtonLimit from "../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import { InputCalendar, InputNumIMask, InputText, InputTextArea, InputTime } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import CardCheck from "../CardCheck";
import { IAddCheck, IDataCheck, IUserData } from "../../../../typec/IAdvanceReport";
import getFirstUpperCase from "../../../../shared/helpers/capitalizedSentence";

const AddCheck = (props: IAddCheck): ReactElement => {
    const { prev = () => null, addCheck = () => null, appID = "", tripID = "", costTypes } = props;
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const [openSlider, setOpenSlider] = useState<"none" | "add" | "set">("none");
    const [userData, setData] = useState<IUserData>({
        type: "photo",
        photos: [],
        comment: "",
        fn: "",
        fd: "",
        fp: "",
        inn: "",
        result: "",
        date: "",
        time: "",
    });
    const [dataCheck, setDataCheck] = useState<IDataCheck>({
        ID: "",
        VidZatrat: "",
        VidZatratID: "",
        INN: "",
        Date: "",
        Time: "",
        ZapolnenoVruchnuyu: false,
        Comment: "",
        Sum: 0,
        FN: "",
        FD: "",
        FP: "",
        Sostav: [],
    });
    const [qrError, setQrError] = useState({
        Error: "",
        ErrorCode: "",
    });
    const [addDone, setAddDone] = useState(false);
    const [addIndexSostav, setAddIndexSostav] = useState<number>(0);
    const [addSostav, setAddSostav] = useState<{
        Name?: string; // Наименование позиции чека
        Comment?: string; // Комментарий от сотрудника по позиции чека
        Number?: number; // Количество позиции чека
        Sum?: number; // Стоимость позиции чека
    }>();
    const [loadFirstBtn, setLoadFirstBtn] = useState(false);
    const [loadSecondBtn, setLoadSecondBtn] = useState(false);

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const clearError = () => {
        setQrError({
            Error: "",
            ErrorCode: "",
        });
    };
    const handleTextClick = (name: "photo" | "write") => {
        setData({
            ...userData,
            type: name,
        });
    };

    const addPhoto = (element) => {
        setData((prevState) => ({
            ...prevState,
            photos: element,
        }));
        clearError();
    };

    const editData = (key, element) => {
        setData((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const editDataInput = (element) => {
        setData((prevState) => ({
            ...prevState,
            ...element,
        }));
    };

    const editSostav = (key, element) => {
        setAddSostav((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const getSostav = (data, index) => {
        setAddSostav({
            Name: data.Sostav[index].Name,
            Sum: data.Sostav[index].Sum,
            Number: data.Sostav[index].Number,
            Comment: data.Sostav[index].Comment,
        });
    };
    const filterCheckItem = (index) => {
        setDataCheck((prevState) => ({
            ...prevState,
            Sostav: prevState.Sostav.filter((el, i) => i != index),
        }));
    };
    const editDataCheck = (key, element) => {
        setDataCheck((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const addSostavCheck = (data) => {
        setDataCheck((prevState) => ({
            ...prevState,
            Sostav: [
                ...prevState.Sostav,
                {
                    Name: data?.Name,
                    Sum: typeof data?.Sum === "string" ? Number(data?.Sum?.replace(",", ".")) : data?.Sum,
                    Number: typeof data?.Number === "string" ? Number(data?.Number?.replace(",", ".")) : data?.Number,
                    Comment: data?.Comment,
                    NDS: 0,
                    SumNDS: 0,
                },
            ],
        }));
    };
    const editSostavCheck = (data, index) => {
        setDataCheck((prevState) => ({
            ...prevState,
            Sostav: [
                ...prevState.Sostav.map((element, i) => {
                    let el = element;
                    if (i == index) {
                        el = {
                            Name: data?.Name,
                            Sum: typeof data?.Sum === "string" ? Number(data?.Sum?.replace(",", ".")) : data?.Sum,
                            Number: typeof data?.Number === "string" ? Number(data?.Number?.replace(",", ".")) : data?.Number,
                            Comment: data?.Comment,
                            NDS: element.NDS,
                            SumNDS: element.SumNDS,
                        };
                    }
                    return el;
                }),
            ],
        }));
    };

    useEffect(() => {
        if (openSlider != "none") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [openSlider]);

    useEffect(() => {
        setDataCheck((prevState) => ({
            ...prevState,
            Sum: Number(
                prevState?.Sostav?.reduce((sum, element) => {
                    if (element.Sum) return parseFloat(`${sum}`) + parseFloat(`${element.Sum}`.replace(",", "."));
                    else return sum;
                }, 0.0).toFixed(2)
            ),
        }));
    }, [dataCheck.Sostav]);

    //отправка фото или данных чека и добавление чека в заявку
    const postDataCheck = () => {
        if (qrError.ErrorCode !== "5") {
            setLoadFirstBtn(true);
            const checkScreen = userData.photos?.map((element, index) => {
                return {
                    Name: `screen_check_${index}.${element.data.split("/")[1].split(";")[0]}`,
                    Content: element.data,
                };
            });
            const checkData = {
                FN: userData.fn,
                FD: userData.fd,
                FP: userData.fp,
                INN: userData.inn,
                Date: userData.date.replace(".2", ".202"),
                Time: userData.time,
                Sum: Number(Number(userData.result?.replace(",", ".")).toFixed(2)),
            };
            fetch("/dev/vacation_checks_new", {
                method: "POST",
                body: JSON.stringify({
                    CheckScreen: userData.type === "photo" ? checkScreen[0] : [],
                    CheckData: userData.type === "write" ? checkData : {},
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setDataCheck({
                            // ID: json?.CheсkData?.ID,
                            ID: "",
                            VidZatrat: dataCheck.VidZatrat,
                            VidZatratID: dataCheck.VidZatratID,
                            Date: json?.CheсkData?.Date,
                            Time: json?.CheckData?.Time,
                            ZapolnenoVruchnuyu: userData.type === "write" ? true : false,
                            Comment: userData.comment,
                            Sum: json?.CheсkData?.Sum,
                            FN: json?.CheckData?.FN,
                            FD: json?.CheckData?.FD,
                            FP: json?.CheckData?.FP,
                            Sostav: json?.CheckData?.Sostav,
                            INN: json?.CheckData?.INN,
                        });
                        setAddDone(true);
                    } else {
                        setQrError({
                            Error: json?.Error,
                            ErrorCode: `${json?.ErrorCode}`,
                        });
                    }
                    setLoadFirstBtn(false);
                });
        } else {
            setDataCheck({
                // ID: json?.CheсkData?.ID,
                ID: "",
                VidZatrat: dataCheck.VidZatrat,
                VidZatratID: dataCheck.VidZatratID,
                Date: "",
                Time: "",
                ZapolnenoVruchnuyu: userData.type === "write" ? true : false,
                Comment: userData.comment,
                Sum: 0,
                FN: "",
                FD: "",
                FP: "",
                Sostav: [],
                INN: "",
            });
            setAddDone(true);
        }
    };

    const saveCheck = (check_data, app_id, trip_id) => {
        setLoadSecondBtn(true);
        clearError();
        fetch("/dev/vacation_business_trips_new", {
            method: "POST",
            body: JSON.stringify({
                CheckData: check_data,
                IDApp: app_id,
                TripID: trip_id,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    addCheck();
                } else {
                    setQrError({
                        Error: json?.Error,
                        ErrorCode: json?.ErrorCode,
                    });
                }
                setLoadSecondBtn(false);
            });
    };

    return (
        <>
            {!addDone ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main_flex"]}>
                        <div>
                            <p className={styles["main_title"]}>Добавление чека</p>
                            <InputDropDown
                                editData={editDataCheck}
                                getItemById={(element) => editDataCheck("VidZatratID", element.ID)}
                                value={dataCheck?.VidZatrat}
                                name="VidZatrat"
                                items={costTypes?.expenses}
                                icon="icon_drop_down"
                                titleText="Выберите вид затрат"
                                objItem
                            />
                            {dataCheck?.VidZatrat ? (
                                <Notification type="info" icon="info" style={{ marginTop: "0" }}>
                                    {costTypes?.expenses.map((element) => {
                                        if (element.Name === dataCheck?.VidZatrat) return getFirstUpperCase(element.Description.replaceAll(/\(|\)/g, ""));
                                    })}
                                </Notification>
                            ) : (
                                <div style={{ marginBottom: "-32px" }} />
                            )}
                            <div className={styles["inp"]}>
                                <InputRadio
                                    style={{ width: "100%" }}
                                    checked={userData.type === "photo"}
                                    onClick={() => handleTextClick("photo")}
                                    firstTitle="Прикрепите фото чека"
                                    id_key="photo"
                                    name="type"
                                />
                            </div>
                            {qrError.Error ? (
                                <Notification type="err_not_margin" icon="alarm" style={{ marginTop: "24px", color: "#CC373C" }}>
                                    {qrError.Error}
                                </Notification>
                            ) : null}
                            {userData.type === "photo" ? (
                                <div key="DivDismissalDocument" className={styles.photos}>
                                    <NewPhotoButtonLimit onChange={(value) => addPhoto(value)} limit={1} data={userData.photos} />
                                </div>
                            ) : null}
                            <div className={styles["inp"]}>
                                <InputRadio
                                    disabled={userData.photos.length > 0 ? false : true}
                                    style={{ width: "100%" }}
                                    checked={userData.type === "write"}
                                    onClick={() => handleTextClick("write")}
                                    firstTitle="Заполнить чек вручную"
                                    id_key="write"
                                    name="type"
                                />
                            </div>
                            {userData.type === "write" ? (
                                <>
                                    <div className={styles.input}>
                                        <InputNumIMask
                                            onChange={(element) => editDataInput({ fn: element.target.value })}
                                            value={userData.fn}
                                            placeholder="9999 0789 0000 2273"
                                            type={"text"}
                                            titleText={"ФН"}
                                            clearError={clearError}
                                        />
                                    </div>
                                    <div className={styles.input}>
                                        <InputNumIMask
                                            onChange={(element) => editDataInput({ fd: element.target.value })}
                                            value={userData.fd}
                                            placeholder="0000 0000 43"
                                            type={"text"}
                                            titleText={"ФД"}
                                            clearError={clearError}
                                        />
                                    </div>
                                    <div className={styles.input}>
                                        <InputNumIMask
                                            onChange={(element) => editDataInput({ fp: element.target.value })}
                                            value={userData.fp}
                                            placeholder="0159 7336 24"
                                            type={"text"}
                                            titleText={"ФП"}
                                            clearError={clearError}
                                        />
                                    </div>
                                    <div className={styles.input}>
                                        <InputNumIMask
                                            onChange={(element) => editDataInput({ inn: element.target.value })}
                                            value={userData.inn}
                                            placeholder="7743013902"
                                            type={"text"}
                                            titleText={"ИНН продавца"}
                                            clearError={clearError}
                                        />
                                    </div>
                                    <div className={styles.input}>
                                        <InputNumIMask
                                            onChange={(element) => editDataInput({ result: element.target.value })}
                                            value={userData.result}
                                            type={"text"}
                                            titleText={"Итог"}
                                            placeholder="4 444"
                                            clearError={clearError}
                                        />
                                    </div>
                                    <InputCalendar
                                        titleTextSlide="Дата"
                                        style={{ marginTop: "24px", marginBottom: "-24px" }}
                                        titleText="Дата"
                                        name="date"
                                        value={userData.date}
                                        placeholder="ДД.ММ.ГГ"
                                        maxDetail="month"
                                        minDetail="decade"
                                        editData={editData}
                                        clearError={clearError}
                                    />
                                    <div className={styles.input}>
                                        <InputTime
                                            onChange={(element) => editDataInput({ time: element.target.value })}
                                            value={userData.time}
                                            type={"text"}
                                            titleText={"Время"}
                                            placeholder="12:00"
                                            clearError={clearError}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div className={styles.input}>
                                <InputTextArea
                                    editData={editData}
                                    name="comment"
                                    value={userData.comment}
                                    type="area"
                                    titleText={"Комментарий"}
                                    placeholder="Новые данные"
                                    counterView={true}
                                    counterValue="200"
                                    style={{ marginBottom: "24px" }}
                                />
                            </div>
                        </div>
                        <Button
                            onClick={() => {
                                postDataCheck();
                            }}
                            type="save_not_margin"
                            load={loadFirstBtn}
                            disabled={!dataCheck.VidZatratID || (qrError.ErrorCode !== "5" && qrError.ErrorCode !== "") || userData.photos.length === 0}
                        >
                            Загрузить чек
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main_flex"]}>
                        <div>
                            <p className={styles["main_title"]}>Добавление чека</p>
                            <InputDropDown
                                editData={editDataCheck}
                                getItemById={(element) => editDataCheck("VidZatratID", element.ID)}
                                value={dataCheck?.VidZatrat}
                                name="VidZatrat"
                                items={costTypes?.expenses}
                                icon="icon_drop_down"
                                titleText="Выберите вид затрат"
                                objItem
                            />
                            {dataCheck?.VidZatrat ? (
                                <Notification type="info" icon="info" style={{ marginTop: "0" }}>
                                    {costTypes?.expenses.map((element) => {
                                        if (element.Name === dataCheck?.VidZatrat) return getFirstUpperCase(element.Description.replaceAll(/\(|\)/g, ""));
                                    })}
                                </Notification>
                            ) : (
                                <div style={{ marginBottom: "-32px" }} />
                            )}
                            {qrError.Error ? (
                                <Notification type="err_not_margin" icon="alarm" style={{ marginTop: "24px", color: "#CC373C" }}>
                                    {qrError.Error}
                                </Notification>
                            ) : null}
                            <p className={styles["main_sub_title_margin"]}>Проверьте данные по чеку</p>
                            <p className={styles["main_sub_text"]}>Удалите позиции, которые не подлежат возмещению</p>
                            {dataCheck?.Sostav?.length > 0 &&
                                dataCheck?.Sostav?.map((element, index) => (
                                    <CardCheck
                                        text={element.Name}
                                        price={element?.Sum?.toString()}
                                        onClick={() => {
                                            setOpenSlider("set");
                                            setAddIndexSostav(index);
                                            getSostav(dataCheck, index);
                                        }}
                                        onDelete={() => {
                                            filterCheckItem(index);
                                        }}
                                    />
                                ))}
                            <Button
                                onClick={() => {
                                    setOpenSlider("add");
                                }}
                                style={{ marginTop: "21px" }}
                                icon="plus-interface"
                                type="change_large"
                            >
                                Добавить позицию
                            </Button>
                            <div className={styles.input}>
                                <InputTextArea
                                    editData={editDataCheck}
                                    name="Comment"
                                    value={dataCheck.Comment}
                                    type="area"
                                    titleText={"Комментарий"}
                                    placeholder="Новые данные"
                                    counterView={true}
                                    counterValue="200"
                                    style={{ marginBottom: "24px" }}
                                />
                            </div>
                        </div>
                        <Button
                            disabled={!dataCheck?.Sostav.length}
                            load={loadSecondBtn}
                            onClick={() => saveCheck(dataCheck, appID, tripID)}
                            style={{ margin: "0", minHeight: "48px" }}
                            type="save_not_margin"
                        >
                            Сохранить чек
                        </Button>
                    </div>
                    {openSlider === "add" ? (
                        <>
                            <SlideScreen
                                titleText="Добавление позиции"
                                onClose={() => {
                                    setOpenSlider("none");
                                    setAddSostav(undefined);
                                }}
                                altitudeLevel="m_580px"
                            >
                                <div className={styles["main_slider"]}>
                                    <div>
                                        <InputText onChange={(e) => editSostav("Name", e)} placeholder="Бумага белая" titleText="Наименование позиции из чека" type="fullString" />
                                        <InputNumIMask
                                            onChange={(e) => editSostav("Number", e.target.value)}
                                            style={{ marginTop: "24px" }}
                                            placeholder="Введите кол-во"
                                            titleText="Количество"
                                            type="text"
                                        />
                                        <InputNumIMask
                                            onChange={(e) => editSostav("Sum", e.target.value)}
                                            style={{ marginTop: "24px" }}
                                            placeholder="44,4"
                                            titleText="Стоимость в рублях"
                                            type="text"
                                            inputMode="decimal"
                                        />
                                        <InputTextArea
                                            editData={editSostav}
                                            name="Comment"
                                            style={{ marginTop: "24px" }}
                                            placeholder="Расшифруйте позицию при необходимости"
                                            titleText="Комментарий"
                                            type="small_64px"
                                        />
                                    </div>
                                    <Button
                                        disabled={addSostav?.Name && addSostav?.Number && addSostav?.Sum ? false : true}
                                        onClick={() => {
                                            addSostavCheck(addSostav);
                                            setOpenSlider("none");
                                            setAddSostav(undefined);
                                        }}
                                        style={{ margin: "0" }}
                                        type="save_not_margin"
                                    >
                                        Добавить позицию
                                    </Button>
                                </div>
                            </SlideScreen>
                        </>
                    ) : openSlider === "set" ? (
                        <>
                            <SlideScreen
                                titleText="Редактирование позиции"
                                onClose={() => {
                                    setOpenSlider("none");
                                    setAddSostav(undefined);
                                }}
                                altitudeLevel="m_580px"
                            >
                                <div className={styles["main_slider"]}>
                                    <div>
                                        <InputText
                                            onChange={(e) => editSostav("Name", e)}
                                            value={addSostav?.Name}
                                            placeholder="Бумага белая"
                                            titleText="Наименование позиции из чека"
                                            type="fullString"
                                        />
                                        <InputNumIMask
                                            onChange={(e) => editSostav("Number", e.target.value)}
                                            value={addSostav?.Number?.toString()}
                                            style={{ marginTop: "24px" }}
                                            placeholder="Введите кол-во"
                                            titleText="Количество"
                                            type="text"
                                        />
                                        <InputNumIMask
                                            onChange={(e) => editSostav("Sum", e.target.value)}
                                            value={`${addSostav?.Sum}`.replace(".", ",")}
                                            style={{ marginTop: "24px" }}
                                            placeholder="44,4"
                                            titleText="Стоимость в рублях"
                                            type="text"
                                            inputMode="decimal"
                                        />
                                        <InputTextArea
                                            editData={editSostav}
                                            name="Comment"
                                            value={addSostav?.Comment}
                                            style={{ marginTop: "24px" }}
                                            placeholder="Расшифруйте позицию при необходимости"
                                            titleText="Комментарий"
                                            type="small_64px"
                                        />
                                    </div>
                                    <Button
                                        disabled={addSostav?.Name && addSostav?.Number && addSostav?.Sum ? false : true}
                                        onClick={() => {
                                            editSostavCheck(addSostav, addIndexSostav);
                                            setOpenSlider("none");
                                            setAddSostav(undefined);
                                        }}
                                        style={{ margin: "0" }}
                                        type="save_not_margin"
                                    >
                                        Сохранить позицию
                                    </Button>
                                </div>
                            </SlideScreen>
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default AddCheck;
