import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import CheckShell from "../CheckShell";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import Loader from "../../../../components/Loader";
import AddCheck from "../AddCheck";
import SetCheck from "../SetCheck";
import Employee from "../Employee";
import { ICompensation, ICostType, IDataCompesation } from "../../../../typec/IAdvanceReport";

const Compensation = (props: ICompensation): ReactElement => {
    const { prev = () => null } = props;
    const [screen, setScreen] = useState<"main" | "addCheck" | "setCheck" | "employee">("main");
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const navigate = useNavigate();
    const [dataStatement, setDataStatement] = useState<IDataCompesation>();
    const [indexCheck, setIndexCheck] = useState<number>(0);
    const [costType, setCostType] = useState<ICostType>();

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const getMainLoad = () => {
        setLoad(true);
        setScreen("main");
    };

    useEffect(() => {
        if (load) {
            fetch(`/dev/vacation_business_trips_new?no_trip="null"`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setDataStatement(json);
                        fetch("/dev/vacation_checks_new", {
                            method: "GET",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                        })
                            .then((res) => res.json())
                            .then((json) => {
                                if (json.res) {
                                    setCostType(json);
                                }
                                setLoad(false);
                            });
                    }
                });
        }
    }, [load]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : screen === "main" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main"]}>
                        <div>
                            <p className={styles["main_title"]}>Возмещение расходов</p>
                            <p className={styles["main_sub_title"]}>Добавьте чеки и выберите вид затрат</p>
                            <Notification type="info" icon="info" style={{ marginTop: "15px" }}>
                                <div>
                                    В{" "}
                                    <a className={styles["main_sub_link"]} onClick={() => navigate("/requestsList")}>
                                        моих заявлениях
                                    </a>{" "}
                                    можно проверить статус заявки на возмещение расходов.
                                </div>
                            </Notification>
                            {dataStatement?.trips?.Checks?.map((element, index) => (
                                <CheckShell
                                    key={`checkShell_compensation_${element.VidZatrat}_${index}`}
                                    onClick={() => {
                                        setIndexCheck(index);
                                        setScreen("setCheck");
                                    }}
                                    date={element.Date}
                                    name={element.VidZatrat}
                                    price={element.Sum.toString()}
                                />
                            ))}
                            <Button onClick={() => setScreen("addCheck")} icon="plus-interface" type="add_vacation" style={{ marginTop: "15px" }}>
                                Добавить чек
                            </Button>
                        </div>
                        {dataStatement?.trips?.Checks ? (
                            <Button onClick={() => setScreen("employee")} style={{ margin: "15px 0 0 0" }} type="save_not_margin">
                                Сформировать заявление
                            </Button>
                        ) : null}
                    </div>
                </>
            ) : screen === "addCheck" ? (
                <>
                    <AddCheck costTypes={costType} addCheck={getMainLoad} prev={() => setScreen("main")} appID={dataStatement?.trips?.IDApp} />
                </>
            ) : screen === "setCheck" ? (
                <>
                    <SetCheck
                        costTypes={costType}
                        setCheck={getMainLoad}
                        data={dataStatement?.trips?.Checks[indexCheck]}
                        appID={dataStatement?.trips?.IDApp}
                        prev={() => setScreen("main")}
                    />
                </>
            ) : (
                <>
                    <Employee prev={() => setScreen("main")} data={dataStatement?.trips?.Checks[indexCheck]} appID={dataStatement?.trips?.IDApp} />
                </>
            )}
        </>
    );
};

export default Compensation;
