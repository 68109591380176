import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../../template";
import SlideScreen from "../../../../../components/SlideScreen";
import Button from "../../../../../components/Button";
import VacationCheck from "../../../components/VacationCheck";
import { InputCalendar, InputError, InputNumIMask } from "../../../../../components/Inputs";
import { IOverlayseVacation, IPaidVacAdd } from "../../../../../typec/IVacations";
import { VacOfSubItemName } from "../../../../DocumentRequest/componets/VacOfSubItem";
import Notification from "../../../../../components/Notification";
import word from "../../../../../shared/helpers/word";
import clsx from "clsx";

const PaidVacAdd = (props: IPaidVacAdd): ReactElement => {
    const { loading = () => null, editPutInfo, valueAmount = "", valueStartDay = "", successPost = () => null, userVacation } = props;
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({
        valueStartDay: valueStartDay,
        quantity: "",
        valueAmount: valueAmount,
        Date: "",
        finishDay: "",
    });
    const [error, setError] = useState({
        err: "",
        text: "",
    });
    const [screenCheck, setScreenCheck] = useState<boolean>(false);

    const [jsonOverlays, setJsonOverlayse] = useState<IOverlayseVacation>();

    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const editData = (element) => {
        setData((prevState) => ({
            ...prevState,
            ...element,
        }));
    };

    const putVac = () => {
        setLoad(true);
        fetch("/dev/vacation_vac_shed_new", {
            method: "PUT",
            body: JSON.stringify({
                Transfer: false,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                setLoad(false);
                if (json.res == true) {
                    loading();
                    setLoad(false);
                } else {
                }
            });
    };

    const postVacation = () => {
        const post = () => {
            fetch("/dev/vacation_vac_shed_new", {
                method: "POST",
                body: JSON.stringify({
                    Transfer: false,
                    Periods: [
                        {
                            StartDateOrigin: data.valueStartDay.replace(".2", ".202"),
                            StartDateTransfer: data.valueStartDay.replace(".2", ".202"),
                            NumberDays: data.valueAmount,
                        },
                    ],
                    Comment: "",
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setLoad(false);
                        editData({
                            Date: json.Date,
                            finishDay: json.KonetsDate,
                        });
                        setScreenCheck(true);
                        if (editPutInfo) {
                            editPutInfo({
                                periodArr: [`c ${data.valueStartDay.replace(".2", ".202")} по ${json.KonetsDate}, ${data.valueAmount} дней`],
                                quantityDay: data.valueAmount,
                                finishDay: json.KonetsDate.replace(".202", ".2"),
                                startDay: data.valueStartDay,
                                headerInfo: { Date: json.Date },
                                typeVacation: "Оплачиваемый отпуск",
                            });
                        }
                        successPost();
                    } else {
                        createError({ text: json.Error, err: json.ErrorCode });
                        setLoad(false);
                    }
                });
        };

        setLoad(true);
        if (!(Number(data.valueAmount) <= Number(data.quantity))) {
            createError({ text: "Количество дней отпуска не может превышать доступное количество дней", err: "ER_HRM01" });
            setLoad(false);
        } else if (!data.valueStartDay && !data.valueAmount) {
            createError({ text: "Не указана дата начала отпуска. Не указано количество дней отпуска", err: "ER_HRM01" });
            setLoad(false);
        } else if (!data.valueStartDay) {
            createError({ text: "Не указана дата начала отпуска", err: "ER_HRM01" });
            setLoad(false);
        } else if (!data.valueAmount) {
            createError({ text: "Не указано количество дней отпуска", err: "ER_HRM01" });
            setLoad(false);
        } else post();
    };

    const dateNow = (): string => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        return `${day}.${month + 1}.${year}`;
    };

    useEffect(() => {
        if (/\d\d\.\d\d\.\d\d/.test(data.valueStartDay)) {
            fetch("/dev/vacation_leftover_new", {
                method: "POST",
                body: JSON.stringify({
                    DateLeftover: data.valueStartDay.replace(".2", ".202"),
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        editData({ quantity: `${json.DaysLeft}`.split(".")[0] });
                        setLoad(false);
                    } else {
                        editData({ quantity: "..." });
                    }
                });
        } else if (!data.valueStartDay) {
            fetch("/dev/vacation_leftover_new", {
                method: "POST",
                body: JSON.stringify({
                    DateLeftover: dateNow(),
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        editData({ quantity: `${json.DaysLeft}`.split(".")[0] });
                        setLoad(false);
                    } else {
                    }
                });
        } else {
            editData({ quantity: "..." });
        }
    }, [data.valueStartDay]);

    useEffect(() => {
        if (context.userdata.overlays_show) {
            fetch("/dev/vacation_list_new", {
                method: "PUT",
                body: JSON.stringify({
                    Periods: [{ StartDate: data.valueStartDay.replace(".2", ".202"), NumberDays: data.valueAmount }],
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        console.log(json);
                        setJsonOverlayse(json);
                    }
                });
        }
    }, [data.valueStartDay, data.valueAmount]);

    const formattedDateParts = (date) => {
        const dateParts = date.split(".").map((part) => (part.startsWith("0") ? part.slice(1) : part));
        return dateParts;
    };

    const minDate = formattedDateParts(userVacation?.min_date_vacation);
    const maxDate = formattedDateParts(userVacation?.max_date_vacation);

    return (
        <>
            <div className={styles.hero}>
                <div>
                    <p className={styles.hero_heading}>
                        Заявление на <br /> оплачиваемый отпуск
                    </p>
                    <div className={styles.hero_count_text}>
                        На {/\d\d\.\d\d\.\d\d/.test(data.valueStartDay) ? data.valueStartDay : "сегодня"} доступно дней: <a>{data.quantity}</a>
                    </div>
                    <div className={styles.hero_container}>
                        <InputCalendar
                            defaultValue={new Date(Number(minDate[2]), Number(minDate[1]) - 1, Number(minDate[0]))}
                            minDate={new Date(Number(minDate[2]), Number(minDate[1]) - 1, Number(minDate[0]))}
                            maxDate={new Date(Number(maxDate[2]), Number(maxDate[1]) - 1, Number(maxDate[0]))}
                            value={data.valueStartDay}
                            clearError={clearError}
                            dateNow={false}
                            editVacation={editData}
                            type="dropdown_small"
                            titleText="Дата начала"
                            key="InpCalendarDismissal"
                            titleTextSlide="Дата начала отпуска"
                            name="valueStartDay"
                            placeholder="ДД.ММ.ГГ"
                            errorMessage={error.text != "" ? " " : undefined}
                        />
                        <InputNumIMask
                            editData={editData}
                            value={data.valueAmount}
                            style={{ marginBottom: "24px" }}
                            type="num"
                            titleText="Кол-во дней"
                            clearError={clearError}
                            placeholder="Введите дни"
                            errorMessage={error.text != "" ? " " : undefined}
                        />
                    </div>
                    {error.text && <InputError style={{ marginTop: "-24px", marginBottom: "40px" }} titleText={error.err != "ER_HRMUN" ? error.text : "Неизвестная ошибка"} />}
                    {context.userdata.overlays_show && data.valueAmount && data.valueStartDay ? (
                        <div style={{ marginTop: "-20px" }}>
                            {jsonOverlays?.Overlays && jsonOverlays?.Overlays?.length > 0 ? (
                                <>
                                    <Notification type="info" icon="info">
                                        Посмотрите, с кем из коллег пересекается ваш отпуск, при необходимости отредактируйте даты.
                                    </Notification>
                                    {jsonOverlays?.Overlays?.map((el) => (
                                        <VacOfSubItemName name={el.Name} startDays={el.StartDate} konetsDays={el.KonetsDate} numberDays={`${el.NumberDays}`} />
                                    ))}
                                </>
                            ) : (
                                <Notification type="info" icon="info">
                                    Ваш отпуск не пересекается с коллегами из отдела!
                                </Notification>
                            )}
                        </div>
                    ) : null}
                </div>

                <Button style={{ marginTop: "24px" }} load={load} type="save_not_margin" onClick={postVacation}>
                    Сформировать заявление
                </Button>
            </div>
        </>
    );
};

export default PaidVacAdd;
