import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { InputCalendar, InputNumIMask } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import Loader from "../../../../components/Loader";

export interface IVacation140KD {
    data?: any;
    setViewBank?: (element: boolean) => void;
    editData?: (key: string, element: string) => void;
    editBIRData?: (numberReportData, startDayData, finishDayData) => void;
}

const Vacation140KD = (props: IVacation140KD): ReactElement => {
    const { data, setViewBank = () => null, editData = () => null, editBIRData = () => null } = props;
    const [load, setLoad] = useState<boolean>(true);
    const [disableInp, setDisableInp] = useState(false);

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_bir_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setDisableInp(json.BIRIsList);
                    }
                    if (json.BIRIsList) editBIRData(json.BIRListNumber, json.BIRStartDate, json.BIRKonetsDate);
                    setLoad(false);
                });
        }
    });
    return (
        <>
            {load ? (
                <Loader />
            ) : (
                <div className={styles.hero}>
                    <div>
                        <p className={styles.hero_main_heading}>Отпуск по беременности и родам</p>
                        {!disableInp ? (
                            <p className={styles.hero_second_title}>Заполните данные с больничного листа</p>
                        ) : (
                            <Notification style={{ marginTop: "18px" }} type="info" icon="info">
                                Проверьте данные по больничному листу, если нашли несоответствие Обратитесь в ОКА.
                            </Notification>
                        )}
                        <InputNumIMask
                            onChange={(e) => editData("numberReport", e.target.value)}
                            value={data.numberReport}
                            style={{ marginTop: "18px" }}
                            type="text"
                            placeholder="123456789"
                            titleText="Номер больничного листа"
                            readOnly={disableInp}
                        />
                        <div className={styles.hero_date}>
                            <InputCalendar
                                disable={disableInp}
                                onlyFuture
                                editData={editData}
                                name="startDay"
                                value={data.startDay}
                                style={{ minWidth: "calc(50% - 6px)" }}
                                type="dropdown"
                                titleText="Дата начала"
                                placeholder="ДД.ММ.ГГ"
                            />
                            <InputCalendar
                                disable={disableInp}
                                onlyFuture
                                editData={editData}
                                name="finishDay"
                                value={data.finishDay}
                                style={{ minWidth: "calc(50% - 6px)" }}
                                type="dropdown"
                                titleText="Дата окончания"
                                placeholder="ДД.ММ.ГГ"
                            />
                        </div>
                    </div>
                    <Button
                        disabled={!(disableInp || (data.numberReport && data.startDay && data.finishDay))}
                        onClick={() => setViewBank(true)}
                        type="save_not_margin"
                        style={{ margin: "0" }}
                    >
                        Заполнить реквизиты карты
                    </Button>
                </div>
            )}
        </>
    );
};

export default Vacation140KD;
