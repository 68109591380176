import React, { ReactElement, useEffect } from "react";
import styles from "./index.module.scss";

const TimeItem = (props): ReactElement => {
    const { type, next, item, handleTime } = props;

    const changeTime = (value) => {
        handleTime(value);
        next();
    };

    const name = item?.Name?.substring(0, item?.Name.length - 5);
    const time = item?.Name?.substring(item?.Name.length - 5);

    return (
        <>
            <div className={styles.item} onClick={() => changeTime({ id: item?.ID, name: item?.Name, type: type, districts: item.Districts })}>
                <span className={styles.item_type}>{name}</span>
                <span className={styles.item_time}>{time}</span>
            </div>
        </>
    );
};

export default TimeItem;
