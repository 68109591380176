import React, { ReactElement, useEffect, useState } from "react";
import styles from "../../index.module.scss";
import Notification from "../../../../../components/Notification";
import ImageItem from "../../../NewAppeal/components/ImageItem";
import Loader from "../../../../../components/Loader";
import { IMyInfoAppeals } from "../../../../../typec/IClientSupport";
import Button from "../../../../../components/Button";
import SlideScreen from "../../../../../components/SlideScreen";

export interface IAppealInfo {
    numberAppeal?: string;
    tag?: string;
    date?: string;
    // req_id?: string;
    req_id: string;
    text?: string;
    key_info?: string;
    closeInfo: () => void;
    getLoad?: () => void;
}

const AppealInfo = (props: IAppealInfo): ReactElement => {
    const { numberAppeal, tag, date, req_id, text, key_info, closeInfo, getLoad = () => null } = props;
    const [load, setLoad] = useState(true);
    const [photoData, setPhotoData] = useState<IMyInfoAppeals>();
    const [cancel, setCancel] = useState(false);
    const [cancelID, setCancelID] = useState("");

    useEffect(() => {
        if (load) {
            fetch(`/dev/vacation_feedback_new?req_id=${req_id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setLoad(false);
                        setPhotoData(json);
                    } else {
                        setLoad(false);
                    }
                });
        }
    }, [load]);

    const switchCancel = (id?: string) => {
        setCancel(cancel ? false : true);
        if (cancel) {
            setCancelID("");
        } else {
            if (id) {
                setCancelID(id);
            }
        }
    };
    const deleteAppeal = (id: string) => {
        // setLoad(true);
        fetch(`/dev/vacation_feedback_new?req_id=${id}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Заявка в поддержку отменена
                    console.log(json);
                    setLoad(true);
                    switchCancel();
                    closeInfo();
                    getLoad();
                } else {
                    // Заявка в поддержку не отменена
                    console.log(json);
                }
            });
    };

    useEffect(() => {
        if (cancel) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [cancel]);

    return (
        <>
            {load ? (
                <Loader />
            ) : (
                <>
                    <div key={`mappl_info_${key_info}`} className={styles.mappl_info}>
                        <div key={`mappl_info_notification_${key_info}`} className={styles.mappl_info_notification}>
                            <Notification type={tag == "Открыта" ? "coordination" : "signed"}>{tag}</Notification>
                        </div>
                        <p key={`mappl_info_title_${key_info}`} className={styles.mappl_info_title}>
                            Обращение №{numberAppeal} от <br /> {date}
                        </p>
                        <p key={`mappl_info_text_${key_info}`} className={styles.mappl_info_text} dangerouslySetInnerHTML={{ __html: text ? text : "" }}></p>
                        {photoData?.request_data?.ListAttachments?.length ? (
                            <>
                                <p key={`mappl_info_title_files_${key_info}`} className={styles.mappl_info_title_files}>
                                    Прикрепленные файлы
                                </p>
                                <div key={`mappl_info_container_files_${key_info}`} className={styles.mappl_info_container_files}>
                                    {photoData?.request_data?.ListAttachments?.map((element, index) => (
                                        <ImageItem increasePhoto={true} deleteBtn={false} src={element.Content} key_photo={`listAttach_photo_${index}`} />
                                    ))}
                                </div>
                            </>
                        ) : null}
                        {photoData?.request_data?.Resolution ? (
                            <>
                                <p key={`mappl_info_title_files_2_${key_info}`} className={styles.mappl_info_title_files}>
                                    Файлы поддержки
                                </p>
                                <p
                                    dangerouslySetInnerHTML={{ __html: photoData?.request_data?.Resolution }}
                                    key={`mappl_info_text_2_${key_info}`}
                                    className={styles.mappl_info_text}
                                ></p>
                                <div key={`mappl_info_container_files_2_${key_info}`} className={styles.mappl_info_container_files}>
                                    {photoData?.request_data?.ListAttachmentsRes?.map((element, index) => (
                                        <ImageItem increasePhoto={true} deleteBtn={false} src={element.Content} key_photo={`listAttach_photo_2_${index}`} />
                                    ))}
                                </div>
                            </>
                        ) : null}
                    </div>
                    {tag != "Закрыта" && tag != "Отменена" ? (
                        <div className={styles.cancel} onClick={() => switchCancel(req_id)}>
                            <Button type="cancel_red">Отменить</Button>
                        </div>
                    ) : null}
                    {cancel && (
                        <SlideScreen altitudeLevel="middle" titleText="Отмена обращения" onClose={() => switchCancel()}>
                            <p className={styles.cancel_title}>Вы уверены что хотите отменить обращение?</p>
                            <Button type="cancel_red" onClick={() => deleteAppeal(req_id)} load={load}>
                                Отменить
                            </Button>
                            <Button type="cancel_grey" style={{ marginTop: "8px" }} onClick={() => switchCancel()}>
                                Закрыть
                            </Button>
                        </SlideScreen>
                    )}
                </>
            )}
        </>
    );
};

export default AppealInfo;
