import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import { InputDropDown } from "../../../../components/Inputs/InputDropDown";
import { InputNumIMask, InputText, InputTextArea } from "../../../../components/Inputs";
import CardCheck from "../CardCheck";
import SlideScreen from "../../../../components/SlideScreen";
import { IDataCheck, ISetCheck, ISostav } from "../../../../typec/IAdvanceReport";
import getFirstUpperCase from "../../../../shared/helpers/capitalizedSentence";

const SetCheck = (props: ISetCheck): ReactElement => {
    const { prev = () => null, data, setCheck = () => null, deleteCheck = () => null, appID = "", tripID = "", costTypes } = props;
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const [openSlider, setOpenSlider] = useState<"none" | "add" | "set">("none");
    const [dataCheck, setDataCheck] = useState<IDataCheck | undefined>(data);
    const [indexSostav, setIndexSostav] = useState<number>(0);
    const [sostav, setSostav] = useState<ISostav>();
    const [error, setError] = useState({
        Error: "",
        ErrorCode: "",
    });
    const [loadBtn, setLoadBtn] = useState(false);
    const [loadDeleteBtn, setLoadDeleteBtn] = useState(false);

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const clearError = () => {
        setError({
            Error: "",
            ErrorCode: "",
        });
    };

    const editDataCheck = (key, element) => {
        setDataCheck((prevState) => {
            if (prevState)
                return {
                    ...prevState,
                    [key]: element,
                };
        });
    };
    const getSostav = (data, index) => {
        setSostav({
            Name: data.Sostav[index].Name,
            Sum: data.Sostav[index].Sum,
            Number: data.Sostav[index].Number,
            Comment: data.Sostav[index].Comment,
        });
    };
    const filterCheckItem = (index) => {
        setDataCheck((prevState) => {
            if (prevState)
                return {
                    ...prevState,
                    Sostav: prevState.Sostav.filter((el, i) => i != index),
                };
        });
    };
    const editSostav = (key, element) => {
        setSostav((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const getDataCheckSostav = (data) => {
        setDataCheck((prevState) => {
            if (prevState)
                return {
                    ...prevState,
                    Sostav: [
                        ...prevState.Sostav,
                        {
                            Name: data?.Name,
                            Sum: typeof data?.Sum === "string" ? Number(data?.Sum?.replace(",", ".")) : data?.Sum,
                            Number: typeof data?.Number === "string" ? Number(data?.Number?.replace(",", ".")) : data?.Number,
                            Comment: data?.Comment,
                            NDS: 0,
                            SumNDS: 0,
                        },
                    ],
                };
        });
    };
    const setDataCheckSostav = (data, index) => {
        setDataCheck((prevState) => {
            if (prevState)
                return {
                    ...prevState,
                    Sostav: [
                        ...prevState.Sostav.map((element, i) => {
                            let el = element;
                            if (i == index) {
                                el = {
                                    Name: data?.Name,
                                    Sum: typeof data?.Sum === "string" ? Number(data?.Sum?.replace(",", ".")) : data?.Sum,
                                    Number: typeof data?.Number === "string" ? Number(data?.Number?.replace(",", ".")) : data?.Number,
                                    Comment: data?.Comment,
                                    NDS: element.NDS,
                                    SumNDS: element.SumNDS,
                                };
                            }
                            return el;
                        }),
                    ],
                };
        });
    };

    const saveEditCheck = (check_data, app_id, trip_id) => {
        setLoadBtn(true);
        clearError();
        fetch("/dev/vacation_business_trips_new", {
            method: "POST",
            body: JSON.stringify({
                CheckData: check_data,
                IDApp: app_id,
                TripID: trip_id,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setCheck();
                } else {
                    setError({
                        Error: json?.Error,
                        ErrorCode: json?.ErrorCode,
                    });
                }
                setLoadBtn(false);
            });
    };

    const deleteEditCheck = (app_id, check_id) => {
        setLoadDeleteBtn(true);
        fetch("/dev/vacation_business_trips_new", {
            method: "PUT",
            body: JSON.stringify({
                IDApp: app_id,
                CheckID: check_id,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setCheck();
                } else {
                    setError({
                        Error: json?.Error,
                        ErrorCode: json?.ErrorCode,
                    });
                }
                setLoadDeleteBtn(false);
            });
    };

    useEffect(() => {
        if (openSlider != "none") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [openSlider]);
    useEffect(() => {
        setDataCheck((prevState) => {
            if (prevState)
                return {
                    ...prevState,
                    Sum: Number(
                        prevState.Sostav.reduce((sum, element) => {
                            if (element.Sum) return parseFloat(`${sum}`) + parseFloat(`${element.Sum}`.replace(",", "."));
                            else return sum;
                        }, 0.0).toFixed(2)
                    ),
                };
        });
    }, [dataCheck?.Sostav]);

    return (
        <>
            <Navigation prev={prev} goToMain={goToMain}></Navigation>
            <div className={styles["main"]}>
                <p className={styles["main_title"]}>Изменение чека</p>
                <InputDropDown
                    editData={editDataCheck}
                    name="VidZatrat"
                    items={costTypes?.expenses}
                    value={dataCheck?.VidZatrat}
                    getItemById={(element) => editDataCheck("VidZatratID", element.ID)}
                    icon="icon_drop_down"
                    titleText="Выберите вид затрат"
                    objItem
                />
                {dataCheck?.VidZatrat ? (
                    <Notification type="info" icon="info" style={{ marginTop: "0" }}>
                        {costTypes?.expenses.map((element) => {
                            if (element.Name === dataCheck?.VidZatrat) return getFirstUpperCase(element.Description.replaceAll(/\(|\)/g, ""));
                        })}
                    </Notification>
                ) : (
                    <div style={{ marginBottom: "-32px" }} />
                )}
                {error.Error ? (
                    <Notification type="err_not_margin" icon="alarm" style={{ marginTop: "24px", color: "#CC373C" }}>
                        {error.Error}
                    </Notification>
                ) : null}
                <p className={styles["main_sub_title_margin"]}>Проверьте данные по чеку</p>
                <p className={styles["main_sub_text"]}>Удалите позиции, которые не подлежат возмещению</p>
                {dataCheck?.Sostav?.map((element, index) => (
                    <CardCheck
                        key={`cardCheck_setCheck_${element.Name}_${index}`}
                        text={element.Name}
                        price={element?.Sum?.toString()}
                        onClick={() => {
                            setOpenSlider("set");
                            setIndexSostav(index);
                            getSostav(dataCheck, index);
                        }}
                        onDelete={() => {
                            {
                                filterCheckItem(index);
                            }
                        }}
                    />
                ))}
                <Button onClick={() => setOpenSlider("add")} style={{ marginTop: "21px" }} icon="plus-interface" type="change_large">
                    Добавить позицию
                </Button>
                <div className={styles.input}>
                    <InputTextArea
                        editData={editDataCheck}
                        name="Comment"
                        value={dataCheck?.Comment}
                        type="area"
                        titleText={"Комментарий"}
                        placeholder="Новые данные"
                        counterView={true}
                        counterValue="200"
                        style={{ marginBottom: "24px" }}
                    />
                </div>
                <Button disabled={!dataCheck?.Sostav.length} load={loadBtn} onClick={() => saveEditCheck(dataCheck, appID, tripID)} type="save_not_margin">
                    Сохранить чек
                </Button>
                <Button load={loadDeleteBtn} onClick={() => deleteEditCheck(appID, dataCheck?.ID)} type="cancel_white">
                    Удалить из списка
                </Button>
            </div>
            {openSlider === "add" ? (
                <>
                    <SlideScreen
                        titleText="Добавление позиции"
                        onClose={() => {
                            setOpenSlider("none");
                            setSostav(undefined);
                        }}
                        altitudeLevel="m_580px"
                    >
                        <div className={styles["main_slider"]}>
                            <div>
                                <InputText onChange={(e) => editSostav("Name", e)} placeholder="Бумага белая" titleText="Наименование позиции из чека" type="fullString" />
                                <InputNumIMask
                                    onChange={(e) => editSostav("Number", e.target.value)}
                                    style={{ marginTop: "24px" }}
                                    placeholder="Введите кол-во"
                                    titleText="Количество"
                                    type="text"
                                />
                                <InputNumIMask
                                    onChange={(e) => editSostav("Sum", e.target.value)}
                                    style={{ marginTop: "24px" }}
                                    placeholder="44,4"
                                    titleText="Стоимость в рублях"
                                    type="text"
                                    inputMode="decimal"
                                />
                                <InputTextArea
                                    editData={editSostav}
                                    name="Comment"
                                    style={{ marginTop: "24px" }}
                                    placeholder="Расшифруйте позицию при необходимости"
                                    titleText="Комментарий"
                                    type="small_64px"
                                />
                            </div>
                            <Button
                                disabled={sostav?.Name && sostav?.Number && sostav?.Sum ? false : true}
                                onClick={() => {
                                    getDataCheckSostav(sostav);
                                    setOpenSlider("none");
                                    setSostav(undefined);
                                }}
                                style={{ margin: "0" }}
                                type="save_not_margin"
                            >
                                Добавить позицию
                            </Button>
                        </div>
                    </SlideScreen>
                </>
            ) : openSlider === "set" ? (
                <>
                    <SlideScreen
                        titleText="Редактирование позиции"
                        onClose={() => {
                            setOpenSlider("none");
                            setSostav(undefined);
                        }}
                        altitudeLevel="m_580px"
                    >
                        <div className={styles["main_slider"]}>
                            <div>
                                <InputText
                                    onChange={(e) => editSostav("Name", e)}
                                    value={sostav?.Name}
                                    placeholder="Бумага белая"
                                    titleText="Наименование позиции из чека"
                                    type="fullString"
                                />
                                <InputNumIMask
                                    onChange={(e) => editSostav("Number", e.target.value)}
                                    value={sostav?.Number?.toString()}
                                    style={{ marginTop: "24px" }}
                                    placeholder="Введите кол-во"
                                    titleText="Количество"
                                    type="text"
                                />
                                <InputNumIMask
                                    onChange={(e) => editSostav("Sum", e.target.value)}
                                    value={`${sostav?.Sum}`.replace(".", ",")}
                                    style={{ marginTop: "24px" }}
                                    placeholder="44,4"
                                    titleText="Стоимость в рублях"
                                    type="text"
                                    inputMode="decimal"
                                />
                                <InputTextArea
                                    editData={editSostav}
                                    name="Comment"
                                    value={sostav?.Comment}
                                    style={{ marginTop: "24px" }}
                                    placeholder="Расшифруйте позицию при необходимости"
                                    titleText="Комментарий"
                                    type="small_64px"
                                />
                            </div>
                            <Button
                                disabled={sostav?.Name && sostav?.Number && sostav?.Sum ? false : true}
                                onClick={() => {
                                    setDataCheckSostav(sostav, indexSostav);
                                    setOpenSlider("none");
                                    setSostav(undefined);
                                }}
                                style={{ margin: "0" }}
                                type="save_not_margin"
                            >
                                Сохранить позицию
                            </Button>
                        </div>
                    </SlideScreen>
                </>
            ) : null}
        </>
    );
};

export default SetCheck;
