import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { InputPhone, InputRadio, InputText } from "../../../components/Inputs";
import Button from "../../../components/Button";
import SlideScreen from "../../../components/SlideScreen";
import word from "../../../shared/helpers/word";

export interface IRecordExist {
    countMembers?: number;
    titleName?: string;
    address?: string;
    countRose?: string;
    contacts?: string;
    data?: any;
    actionElement?: () => void;
    switchRole?: (boolean) => void;
    getLoad?: () => void;
}

const RecordExist = (props: IRecordExist): ReactElement => {
    const { actionElement = () => null, data, switchRole = () => null, getLoad = () => null } = props;
    const [cancel, setCancel] = useState(false);
    const [cancelID, setCancelID] = useState("");
    const [load, setLoad] = useState(false);
    const [fondData, setFondData] = useState<any>(() => {
        const newData = data?.data?.users?.map((el) => {
            return {
                phone: el.phone_number_pay,
                name: el.full_name_pay,
                sum: el.sum_pay,
                bank: el.bank_name_pay,
            };
        });
        return newData;
    });

    // [
    //     {
    //         phone: data.data.phone,
    //         name: "",
    //         sum: "",
    //         bank: "",
    //     },
    // ]

    const [err, setError] = useState("");

    const createError = (element) => {
        setError(element);
    };
    const clearError = () => {
        setError("");
    };

    const editData = (name: string, element: string, index) => {
        setFondData((prevState) => {
            const newData = prevState?.map((el, i) => {
                if (index == i) {
                    return {
                        ...el,
                        [name]: element,
                    };
                } else {
                    return el;
                }
            });
            return newData;
        });
    };
    const updateData = () => {
        setFondData(() => {
            const newData = data?.data?.users?.map((el) => {
                return {
                    phone: el.phone_number_pay,
                    name: el.full_name_pay,
                    sum: el.sum_pay,
                    bank: el.bank_name_pay,
                };
            });
            return newData;
        });
    };

    const switchCancel = (id?: string) => {
        setCancel(cancel ? false : true);
        if (cancel) {
            setCancelID("");
        } else {
            if (id) {
                setCancelID(id);
            }
        }
    };

    const deleteRecord = (id: string) => {
        setLoad(true);
        fetch(`/dev/vacation_rose_users?id_=${id}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then(() => {
                setLoad(false);
                switchCancel();
                getLoad();
            });
    };

    const switchPay = (bool: boolean, id, phone_number_pay = "", full_name_pay = "", bank_name_pay = "", sum_pay = "") => {
        fetch("/dev/vacation_rose_users", {
            method: "PUT",
            body: JSON.stringify({
                id_: id,
                will_pay: bool,
                phone_number_pay: phone_number_pay,
                full_name_pay: full_name_pay,
                bank_name_pay: bank_name_pay,
                sum_pay: sum_pay,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (!json.res) createError(json.err);
                getLoad();
            });
    };
    useEffect(() => {
        console.log(fondData);
    });
    return (
        <>
            <div className={styles.record}>
                <p className={styles.record_title}>Дари по розе каждому продавцу с фразой: «Спасибо, что ты с нами! Мы все - семья SOKOLOV!» </p>
                <p className={styles.record_title}> Мужчине тоже дари розу с фразой: «Спасибо, что ты с нами! Мы все - семья SOKOLOV! Подари эту розу любимой женщине».</p>
                {data?.data?.users?.map((element, index) => (
                    <div key={`record_ex_${element?.id}`} className={styles.record_container}>
                        <p className={styles.record_container_title}>
                            {element.store_name} <span className={styles.record_container_title_time}>{element.local_time.replace(":00.000", "")}</span>
                        </p>
                        <p className={styles.record_container_sub_title}>{element.address}</p>
                        <p className={styles.record_container_counter}>
                            {element.employees_number} {word(element.employees_number, ["роза", "розы", "роз"])}
                        </p>
                        <p className={styles.record_container_inp_title}>Сможете самостоятельно купить розы или нужна помощь из&nbsp;фонда?</p>
                        <div className={styles.record_container_many}>
                            <div className={styles.record_container_many_question}>
                                <InputRadio
                                    checked={element.will_pay}
                                    onClick={() => switchPay(true, element.id)}
                                    secondTitle={element.users_signed > 1 ? "Купим сами" : "Куплю сам(а)"}
                                    id_key={`will_pay_true_${element.id}`}
                                    name={element.id}
                                />
                            </div>
                            <div className={styles.record_container_many_question}>
                                <InputRadio
                                    checked={!element.will_pay}
                                    onClick={() => switchPay(false, element.id, element.phone_number_pay, element.full_name_pay, element.bank_name_pay)}
                                    secondTitle="Из фонда"
                                    id_key={`will_pay_false_${element.id}`}
                                    name={element.id}
                                />
                            </div>
                        </div>
                        {!element.will_pay && (
                            <div key={`record_ex_${element?.id}_index_${index}`}>
                                <InputPhone
                                    // editData={editData}
                                    onChange={(value) => editData("phone", value, index)}
                                    name="phone"
                                    type="phone"
                                    titleText="Номер телефона для СБП"
                                    placeholder="+7 999 999 99 99"
                                    value={fondData[index].phone}
                                    errorMessage={err}
                                    clearError={clearError}
                                />
                                <InputText
                                    onChange={(value) => editData("name", value, index)}
                                    style={{ marginTop: "16px" }}
                                    type="fullString"
                                    titleText="Имя получателя"
                                    placeholder="Дмитрий Сергеевич Д."
                                    value={fondData[index].name}
                                />
                                <InputText
                                    style={{ marginTop: "16px" }}
                                    type="fullString"
                                    titleText="Сумма"
                                    onChange={(value) => editData("sum", value, index)}
                                    placeholder="Введите сумму"
                                    value={fondData[index].sum}
                                />
                                <InputText
                                    onChange={(value) => editData("bank", value, index)}
                                    style={{ marginTop: "16px", marginBottom: "16px" }}
                                    type="fullString"
                                    titleText="Банк получения средств"
                                    placeholder="Сбер/Тинькофф/Альфа и т.д."
                                    value={fondData[index].bank}
                                />
                                <Button
                                    disabled={
                                        fondData[index].bank == "" || fondData[index].name == "" || fondData[index].phone.length < 12 || fondData[index].sum == "" ? true : false
                                    }
                                    type="change"
                                    style={{ marginBottom: "32px" }}
                                    onClick={() => {
                                        switchPay(false, element.id, fondData[index].phone, fondData[index].name, fondData[index].bank, fondData[index].sum);
                                        // updateData();
                                    }}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        )}
                        {element.users_required === 2 && (
                            <>
                                <div className={styles.record_container_contacts}>
                                    Контакты другого участника акции:
                                    <p className={styles.record_container_contacts_item}>
                                        {element.other_users[0]?.full_name} {element.other_users[0]?.phone_number}
                                    </p>
                                </div>
                            </>
                        )}
                        <div className={styles.record_container_btn}>
                            {element.users_required > 2 && (
                                <Button
                                    type="change"
                                    onClick={() => {
                                        const tg = window.Telegram.WebApp;
                                        tg.openTelegramLink(element.tg_link);
                                    }}
                                >
                                    Чат
                                </Button>
                            )}
                            <Button style={{ marginBottom: "10px" }} type="cancel" onClick={() => switchCancel(element.id)}>
                                Отменить
                            </Button>
                        </div>
                    </div>
                ))}
                <div style={{ marginTop: "150px" }} />
                <SlideScreen altitudeLevel="service">
                    <Button
                        type="cancel_grey"
                        onClick={() => {
                            actionElement();
                            switchRole(true);
                        }}
                    >
                        Добавить участника
                    </Button>
                </SlideScreen>
                {cancel && (
                    <SlideScreen altitudeLevel="middle" titleText="Отмена участия" onClose={() => switchCancel()}>
                        <p className={styles.record_cancel_title}>Вы уверены что хотите отменить участие в акции?</p>
                        <Button type="cancel_red" onClick={() => deleteRecord(cancelID)} load={load}>
                            Отменить
                        </Button>
                        <Button type="cancel_grey" style={{ marginTop: "8px" }} onClick={() => switchCancel()}>
                            Закрыть
                        </Button>
                    </SlideScreen>
                )}
            </div>
        </>
    );
};

export default RecordExist;
