import React, { ReactElement } from "react";
import styles from "./index.module.scss";

export interface ILeaveItem {
    IconStr: React.FC<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
    text?: string;
}

const LeaveItem = (props: ILeaveItem): ReactElement => {
    const { IconStr, onClick = () => null, text } = props;
    return (
        <>
            <div className={styles.container} onClick={() => onClick()}>
                <div className={styles.container_icon}>{IconStr ? <IconStr {...props} /> : null}</div>
                <p className={styles.container_text}>{text}</p>
            </div>
        </>
    );
};

export default LeaveItem;
