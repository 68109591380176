import React, { ReactElement } from "react";
import styles from "../../../index.module.scss";
import clsx from "clsx";
import { IVacOfSubItemNamerafik } from "../../../../../typec/IDocumentRequest";

const VacOfSubItemNameGrafik = (props: IVacOfSubItemNamerafik): ReactElement => {
    const { DolzhnostName, typeVac, name, startDays, konetsDays, numberDays, onClick = () => null, isActual = true, isActiv = false } = props;
    return (
        <>
            <div className={clsx(styles["doc_vac_sub_item"], !isActual && styles["doc_vac_sub_item_not_actual"], isActiv && styles["doc_vac_sub_item_activ"])}>
                <p className={styles.doc_vac_sub_item_title}>{typeVac}</p>
                <p className={clsx(styles["doc_vac_sub_item_text_Ost"], !numberDays && styles["doc_vac_sub_item_text_Ost"])}>{name}</p>
                <p className={clsx(styles["doc_vac_sub_item_text"], !numberDays && styles["doc_vac_sub_item_text_only"])}>{DolzhnostName}</p>
                {numberDays && startDays && konetsDays && (
                    <div className={styles.doc_vac_sub_item_date}>
                        <p className={styles.doc_vac_sub_item_date_text}>
                            {startDays} – {konetsDays}
                        </p>
                        <p className={styles.doc_vac_sub_item_date_quantity}>{numberDays} дней</p>
                    </div>
                )}
            </div>
        </>
    );
};

export { VacOfSubItemNameGrafik };
