import React, { ReactElement, useEffect } from "react";
import styles from "./index.module.scss";
import Close from "../../icons/Close.svg";
import clsx from "clsx";

export interface IVacationEditProps {
    children: React.ReactNode;
    titleText?: string;
    altitudeLevel: "full" | "middle" | "auto" | "service" | "calendar" | "children" | "bank" | "notification" | "m_580px";
    show?: (el) => void;
    onClose?: () => void;
}

const SlideScreen = (props: IVacationEditProps): ReactElement => {
    const { children, titleText, altitudeLevel, show, onClose = () => null } = props;

    const closeWindow = () => {
        if (show) {
            show({ show: false, showCancel: false, modified: false });
        }
        onClose();
    };

    return (
        <>
            {altitudeLevel === "service" ? (
                <>
                    <div className={styles.service}>{children}</div>
                </>
            ) : (
                <>
                    <div className={styles.dark} />
                    <div id="edit_auto" className={clsx(styles["edit"], styles[`edit_${altitudeLevel}`])}>
                        <div className={styles.title}>
                            <p className={styles.title_text}>{titleText}</p>
                            <button className={styles.title_close} onClick={closeWindow}>
                                <Close />
                            </button>
                        </div>
                        <div className={styles[`edit_heigh`]}>{children}</div>
                    </div>
                </>
            )}
        </>
    );
};

export default SlideScreen;
