import React, { LegacyRef, ReactElement, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IMask, IMaskInput, useIMask } from "react-imask";
import { IInputTime } from "../../../typec/IInputs";

const InputTime = (props: IInputTime): ReactElement => {
    const {
        onChange = () => null,
        disable = false,
        value = "",
        type,
        titleText,
        clearError,
        editData,
        errorMessage,
        placeholder,
        style,
        onBlur = () => null,
        onFocus = () => null,
    } = props;
    const [ifValue, setValue] = useState(false);

    const [options] = useState({
        mask: "HH:MM",
        blocks: {
            HH: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
                maxLength: 2,
            },

            MM: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
                maxLength: 2,
            },
        },
        lazy: true,
        unmask: true,
    });

    const { ref } = useIMask<HTMLInputElement & typeof IMaskInput>(options, {
        onAccept: (value) => {
            editData && editData({ valueAmount: value });
            clearError && clearError("text");
            if (/\d/.test(value)) {
                setValue(true);
            } else {
                setValue(false);
            }
        },
    });

    const keyDownBlur = (e) => {
        if (e.key === "Enter" || e.key === "enter") e.target.blur();
    };

    return (
        <div style={style}>
            {titleText && <InputTitle titleText={titleText} />}
            <input
                inputMode="numeric"
                disabled={disable}
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                defaultValue={value}
                onChange={(e) => onChange(e)}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`], !ifValue && styles[`inp_val`])}
                placeholder={placeholder}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
                onKeyDown={(e) => keyDownBlur(e)}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputTime };
