import React, { ReactElement, useContext, useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import styles from "./../index.module.scss";
import VacationInputItem from "../../../components/VacationEdit/components/VacationInputItem";
import { InputCheckBox } from "../../../../../components/Inputs";
import { IOverlayseVacation, IVacationEditProps } from "../../../../../typec/IVacations";
import word from "../../../../../shared/helpers/word";
import Loader from "../../../../../components/Loader";
import { VacOfSubItemName } from "../../../../DocumentRequest/componets/VacOfSubItem";
import Notification from "../../../../../components/Notification";
import { NavigationContext } from "../../../../../template";
import { VacationNext } from "../../index";
import { InputDropDown, InputCalendar } from "../../../../../components/Inputs";
import { useDebounce } from "../../../../../shared/helpers/useDebounce";
import { VacOfSubItemNameGrafik } from "../../../../DocumentRequest/componets/VacOfSubItem";
export interface INextYears {
    actionElement?: () => void;
    reasons: any;
    error?: any;
    clearError?: any;
    minDate?: Date;
    valueStartDay?: any;
    editVacation?: any;
    load?: any;
    valueAmount?: any;
    postVacation?: any;
    setShowChange?: any;
    periodFinish?: any;
    periodFinishEndDate?: any;
    editData?: any;
    editDataType?: any;
    inputCount?: any;
    setInputCount?: any;
    create?: (el: any) => any;
    deleteArrEl?: (el: any) => any;
    setVacations?: any;
    arr?: any;
    show?: any;
}
const VacationDelete = (props: IVacationEditProps): ReactElement => {
    const {
        valueCause = "",
        valueStartDay = "",
        valueAmount,
        editVacation,
        postVacation,
        error,
        clearError,
        load,
        textButton,
        causeBool = false,
        editForIT = false,
        quantityDay,
        dateNow,
        typeVacation,
        editDevideVacation = () => null,
        defValueAmount,
        switchDivadeBool = () => null,
        divadeBool,
        idVacationOnTransition,
        addVacationExternal,
        defValueStartDay,
        disableDivade = false,
        valueStartDayDivade,
        userVacation,
        divadeVacation,
        oldValueFinishDay,
        oldValueStartDay,
        oldValueAmount,
        typeText = "",
        valueCauseid = "",
        setArr,
        show,
        setShowChange,
    } = props;
    const { actionElement = () => null, reasons } = props;
    const context = useContext(NavigationContext);
    const [disableDate, setDisableDate] = useState(causeBool);
    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);
    const [numberkolvo, setnumberkolvo] = useState(false);
    const [numberkolvoOst, setnumberkolvoOst] = useState(false);
    const [jsonOverlaysOst, setJsonOverlayseOst] = useState<any>();
    const contextOverlaysShow = useContext(NavigationContext);
    const Next = useContext(VacationNext);
    const [userData, setData] = useState({
        dataStart: "",
        type: "",
        kolvo: "",
        Name: "",
        ID: "",
        inputCount: "",
    });

    const [jsonOverlays, setJsonOverlayse] = useState<IOverlayseVacation>();
    const [Name1, setErrName1] = useState("");
    const [kolvo1, setErrkolvo1] = useState<any>();
    const [Start1, setErrStart1] = useState("");
    const [err, setErr] = useState("");
    const [loader, setLoad] = useState(false);
    const [counterDay, setCounterDay] = useState(0);
    const [hasMounted, sethasMounted] = useState(false);
    useEffect(() => {
        if (!hasMounted) {
            sethasMounted(true);
            setErrName1(valueCause);
            setErrkolvo1(valueAmount);
            setErrStart1(valueStartDay.replace(".2", ".202"));
        }
    }, []);
    const deleteVac = () => {
        const results = Next.arr.filter((el) => el.StartDate != Start1);
        props.setArr(results);
        props.setShowChange(false);
    };
    const changeVac = () => {
        periodFinishEndDate();
    };
    const periodFinishEndDate = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_cancel_new", {
            method: "PUT",
            body: JSON.stringify({
                Comment: "",
                ID: valueCauseid,
                StartDate: valueStartDay.replace(".2", ".202"),
                NumberDays: valueAmount,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    Next.create(
                        {
                            ID: valueCauseid,
                            KonetsDate: json.KonetsDate,
                            NumberDays: valueAmount,
                            StartDate: valueStartDay.replace(".2", ".202"),
                            Name: valueCause,
                        },
                        {
                            kolvo1,
                            Start1,
                            Name1,
                        }
                    );
                    props.setShowChange(false);
                } else {
                    // Заявление не сформировано
                    setErr(json.Error);
                    setLoad(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };
    const changeSubmit = useDebounce((valueStartDay, valueAmount) => {
        if (valueStartDay !== "" && valueAmount !== "") {
            setnumberkolvo(false);
            setCounterDay(-1);
            setnumberkolvoOst(false);
            if (Number(valueAmount) < 30)
                fetch("/dev/vacation_vac_check_new", {
                    method: "POST",
                    body: JSON.stringify({
                        Comment: "",
                        ID: valueCauseid,
                        StartDate: valueStartDay.replace(".2", ".202"),
                        // NumberDays: valueAmount,
                        NumberDays: 1,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.res) {
                            setLoad(false);
                            setnumberkolvo(true);
                            setCounterDay(json.Ostatok);
                            setErr("");
                        } else {
                            // Заявление не сформировано
                            setErr(json.Error);
                            setLoad(false);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            if (contextOverlaysShow.userdata.overlays_show === true)
                fetch("/dev/vacation_schedule_new_overlays", {
                    method: "POST",
                    body: JSON.stringify({
                        StartDate: valueStartDay.replace(".2", ".202"),
                        NumberDays: valueAmount,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.res) {
                            console.log(json);
                            setnumberkolvoOst(true);
                            setLoad(false);
                            setJsonOverlayseOst(json);
                            // props.setVacations(true);
                            setErr("");
                        } else {
                            // Заявление не сформировано
                            setErr(json.Error);
                            console.log(json);
                            setLoad(false);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            ``;
        }
    }, 800);
    useEffect(() => {
        changeSubmit(valueStartDay, valueAmount);
    }, [valueStartDay, valueAmount]);

    return (
        <>
            {loader ? (
                <>
                    <Loader></Loader>
                </>
            ) : null}
            <div className={styles.edit_container}>
                <div className={styles.infoCheck_zagolovok}>
                    Тип отпуска: <span className={styles.infoCheck_text}>{valueCause}</span>
                </div>
                {numberkolvo ? (
                    <>
                        <div className={styles.null_count_text}>
                            Доступные дни: <a>{counterDay}</a>
                        </div>
                    </>
                ) : (
                    <div className={styles.null_count_text}></div>
                )}
                <div className={styles.error}>{err}</div>
                <div className={styles.edit_vac}>
                    <VacationInputItem
                        minDate={editForIT ? undefined : new Date(templateYear, 12, 1)}
                        maxDate={editForIT ? undefined : new Date(templateYear + 1, 11, 31)}
                        editVacation={editVacation}
                        error={error}
                        clearError={clearError}
                        typeEditInput="date"
                        titleText="Дата начала"
                        defaultValueInput={valueStartDay.replace(".2", ".202")}
                        disable={disableDate}
                        dateNow={editForIT}
                    />

                    <VacationInputItem
                        editVacation={editVacation}
                        error={error}
                        clearError={clearError}
                        typeEditInput="num"
                        titleText="Кол-во дней"
                        defaultValueInput={valueAmount ? valueAmount : 0}
                    />
                </div>
                {numberkolvoOst && contextOverlaysShow ? (
                    <>
                        <div className={styles.top}>
                            {jsonOverlaysOst?.Overlays?.length > 0 ? (
                                <Notification type="info" icon="info">
                                    Посмотрите, с кем из коллег пересекается ваш отпуск, при необходимости отредактируйте даты.
                                </Notification>
                            ) : null}
                        </div>
                        <div className={styles.periodArrIntersects}>
                            {jsonOverlaysOst?.Overlays?.map((el, index) => (
                                <VacOfSubItemNameGrafik
                                    name={el.Name}
                                    DolzhnostName={el.DolzhnostName}
                                    startDays={el.StartDate}
                                    konetsDays={el.KonetsDate}
                                    numberDays={`${el.NumberDays}`}
                                />
                            ))}
                            <div className={styles.margin}></div>
                            <div>&nbsp;</div>
                        </div>
                    </>
                ) : (
                    <div className={styles.null_count_text}></div>
                )}
                <Button type="cancel_red" load={load} onClick={deleteVac}>
                    Удалить отпуск
                </Button>
                <Button type="save" load={load} disabled={valueCauseid == "" || valueStartDay == "" || valueAmount == "" || counterDay < 0} onClick={changeVac}>
                    {textButton && textButton}
                </Button>
                <div>&nbsp;</div>
            </div>
        </>
    );
};

export default VacationDelete;
