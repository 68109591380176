import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Card from "./components/Card";
import BusinessTrip from "./components/BusinessTrip";
import Compensation from "./components/Compensation";
import { IDataTrip } from "../../typec/IAdvanceReport";

const AdvanceReport = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [screen, setScreen] = useState<number | null>(null);
    const [currentID, setCurrentID] = useState("");
    const [dataTrips, setDataTrips] = useState<IDataTrip>();
    const navigate = useNavigate();
    const prev = () => {
        if (typeof screen == "number") setScreen(null);
        else navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const cardName = [
        "Служебная поездка",
        "Возмещение расходов",
        // "Представительские расходы",
        //"Мотивационные программы",
    ];

    const fetchRedirectID = async (id) => {
        setLoad(true);
        try {
            const [response1, response2] = await Promise.all([
                fetch(`/dev/vacation_business_trips_new`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }),
                fetch(`/dev/vacation_business_trips_new?no_trip="null"`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }),
            ]);
            const [data1, data2] = await Promise.all([response1.json(), response2.json()]);
            if (data1?.trips?.some((element) => element.ID.includes(id))) {
                setDataTrips(data1);
                setCurrentID(id);
                setScreen(0);
            } else if (data2?.trips?.IDApp?.includes(id)) {
                setScreen(1);
            }
            console.log(data2);
            setLoad(false);
            context.clearDataRedirect();
        } catch {
            setLoad(false);
            context.clearDataRedirect();
        }
    };

    useEffect(() => {
        if (context.dataRedirect?.app_id) {
            fetchRedirectID(context.dataRedirect.app_id);
        }
    }, []);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : typeof screen == "object" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main"]}>
                        <p className={styles["main_title"]}>Авансовый отчет</p>
                        <p className={styles["main_sub_title"]}>Выберите место возникновения затрат</p>
                        <div className={styles["main_card-list"]}>
                            {cardName?.map((element, index) => (
                                <Card key={`card_in_advReport_${index}_${element}`} icon="ruble_bold_not_wh" text={element} onClick={() => setScreen(index)} />
                            ))}
                        </div>
                    </div>
                </>
            ) : screen === 0 ? (
                <>
                    <BusinessTrip prev={prev} currentID={currentID} dataTrips={dataTrips} />
                </>
            ) : screen === 1 ? (
                <>
                    <Compensation prev={prev} />
                </>
            ) : null}
        </>
    );
};

export default AdvanceReport;
