import React, { ReactElement } from "react";
import styles from "./index.module.scss";

export interface ICardNameExp {
    text?: string;
    sum?: number;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CardNameExp = (props: ICardNameExp): ReactElement => {
    const { sum, text, onClick = () => null } = props;
    return (
        <div onClick={onClick} className={styles.card}>
            <div className={styles.card_text}>{text}</div>
            <div className={styles.card_sum}>{sum?.toFixed(2)}&#8381;</div>
        </div>
    );
};

export default CardNameExp;
