// import React from "react";
import Template from "./template";
import {
    FetchTransport,
    initializeFaro,
    WebVitalsInstrumentation,
    ConsoleInstrumentation,
    LogLevel,
    SessionInstrumentation,
    ErrorsInstrumentation,
    ConsoleTransport,
    getWebInstrumentations,
} from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { useEffect } from "react";

const App = () => {
    useEffect(() => {
        const parseURL = new URL(window.location.href);
        if (parseURL.port === "") {
            initializeFaro({
                transports: [
                    new FetchTransport({
                        url: "https://mltp.sokolov.io/collect",
                        apiKey: "xohQuee6ohj2eethiiyei1Ra",
                    }),
                    new ConsoleTransport(),
                ],
                instrumentations: [
                    ...getWebInstrumentations(),
                    new WebVitalsInstrumentation(),
                    new TracingInstrumentation({
                        instrumentations: [new DocumentLoadInstrumentation()],
                    }),
                    new ConsoleInstrumentation({
                        disabledLevels: [LogLevel.INFO, LogLevel.LOG, LogLevel.WARN, LogLevel.DEBUG],
                    }),
                    new SessionInstrumentation(),
                    new ErrorsInstrumentation(),
                ],
                app: {
                    name: "hr-bot-app",
                    version: "1.0.0",
                },
            });
        }
    }, []);

    return <Template />;
};

export default App;
