import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import { IVacScheduleItem } from "../../../../typec/IDocumentRequest";
import Notification from "../../../../components/Notification";
import clsx from "clsx";

const VacScheduleItem = (props: IVacScheduleItem): ReactElement => {
    const { typeVacationName, startDate, konetsDate, numberDays, status } = props;
    return (
        <>
            <div
                className={clsx(
                    styles["doc_vac_schedule_item"],
                    status === "Вы в отпуске" && styles["doc_vac_schedule_item_active"],
                    status === "Не оформлен" && styles["doc_vac_schedule_item_active"],
                    status === "Не оформлен" && styles["doc_vac_schedule_item_active"],
                    status === "Отгулян" && styles["doc_vac_schedule_item_opacity"]
                )}
            >
                <div className={styles.doc_vac_schedule_item_date}>
                    <p className={styles.doc_vac_schedule_item_date_text}>
                        {startDate} – {konetsDate}
                    </p>
                    <p className={styles.doc_vac_schedule_item_date_quantity}>{numberDays} дней</p>
                </div>
                <div className={styles.doc_vac_schedule_item_info}>
                    <p className={styles.doc_vac_schedule_item_info_title}>{typeVacationName}</p>
                    <Notification
                        type={status === "Оформлен" || status === "Запланирован" ? "signed" : status === "Вы в отпуске" ? "blue" : status === "Не оформлен" ? "red" : "grey"}
                    >
                        {status}
                    </Notification>
                </div>
            </div>
        </>
    );
};

export default VacScheduleItem;
