import React, { ReactElement, useContext } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../../components/Navigation";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/Notification";
import CardDetails from "../CardDetails";
import { IExpensesDetails } from "../../../../typec/IExpensesForAddServices";
import getFirstUpperCase from "../../../../shared/helpers/capitalizedSentence";

const ExpensesDetails = (props: IExpensesDetails): ReactElement => {
    const { data, prev = () => null, title, comment } = props;
    const context = useContext(NavigationContext);

    const navigate = useNavigate();
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    return (
        <>
            <Navigation prev={prev} goToMain={goToMain} />
            <div className={styles["main"]}>
                <p className={styles["main_title"]}>{title}</p>

                {comment ? (
                    <Notification style={{ marginTop: "11px" }} icon="info" type="info">
                        {getFirstUpperCase(comment.toLowerCase())}
                    </Notification>
                ) : null}
                <div className={styles["main_container"]}>
                    <p className={styles["main_sub_title"]}>{data?.Name}</p>
                    <p className={styles["main_sub_sum"]}>{data?.Sum.toFixed(2)}&#8381;</p>
                </div>
                <div>
                    {data?.Items?.map((element, index) => (
                        <CardDetails key={`cardItem_${element.Name}_${index}`} text={element.Name} textIcon={element.Date.slice(0, 5)} sum={element.Sum} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default ExpensesDetails;
