import React, { ReactElement, useState } from "react";
import styles from "./index.module.scss";
import Notification from "../../../../components/Notification";
import Button from "../../../../components/Button";
import { ITimeOffItem } from "../../../../typec/ITimeOff";
import SlideScreen from "../../../../components/SlideScreen";
import { InputCalendar, InputTitle } from "../../../../components/Inputs";
import clsx from "clsx";

const TimeOffItem = (props: ITimeOffItem): ReactElement => {
    const { clearError = () => null, editData = () => null, load, data, disableEdit = false } = props;

    const [visibleEdit, setVisibleEdit] = useState(false);
    const [error, setError] = useState("");
    const [loadLocal, setLoadLocal] = useState(false);
    const oldDay = data?.startDay;
    const [dataItem, setDataItem] = useState({
        ID: data?.ID,
        startDay: data?.startDay,
        transferDay: data?.transferDay != "" ? data?.transferDay : data?.startDay,
        forDay: data?.forDay,
        status: data?.status,
        maxDateTransfer: data?.maxDateTransfer,
        minDateTransfer: data?.minDateTransfer,
        replaceTransferButton: data?.replaceTransferButton,
    });

    const editItem = (key: string, element: string) => {
        setDataItem({
            ...dataItem,
            [key]: element.replace(".2", ".202"),
        });
    };

    const buffEdit = () => {
        setLoadLocal(true);
        fetch("/dev/vacation_vac_check_new", {
            method: "PUT",
            body: JSON.stringify({
                DateTransfer: dataItem.transferDay,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    editData(dataItem);
                    setVisibleEdit(false);
                } else {
                    setError(json.Error);
                }
                setLoadLocal(false);
            });
    };

    const clearErrorAll = () => {
        clearError();
        setError("");
    };

    return (
        <>
            <div
                className={clsx(
                    styles["item"],
                    dataItem.startDay != dataItem.transferDay && dataItem.status && styles["item_modified"],
                    data?.replaceTransferButton === "Вы в отгуле" && styles["item_on_active"]
                )}
            >
                <div className={styles.item_container}>
                    <div className={styles.item_container_day}>
                        {dataItem.startDay != dataItem.transferDay && dataItem.transferDay != "" ? (
                            <span className={styles.item_container_day_old}>
                                <s>{dataItem.startDay}</s>
                            </span>
                        ) : null}
                        <span className={styles.item_container_day_actual}>{dataItem.startDay != dataItem.transferDay ? dataItem.transferDay : dataItem.startDay}</span>
                    </div>
                    <span className={styles.item_status}>
                        {/* {dataItem.status ? (
                            <>
                                {disableEdit ? null : (
                                    <Button icon="change" type="change" onClick={() => setVisibleEdit(true)}>
                                        Изменить
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Notification children="На рассмотрении" type="coordination" />
                        )} */}
                        {!data?.replaceTransferButton?.length ? (
                            <>
                                <Button icon="change" type="change" onClick={() => setVisibleEdit(true)}>
                                    Изменить
                                </Button>
                            </>
                        ) : data?.replaceTransferButton === "Оформлен" ? (
                            <>
                                <Notification type="signed">{data.replaceTransferButton}</Notification>
                            </>
                        ) : data.replaceTransferButton === "Вы в отгуле" ? (
                            <>
                                <Notification type="blue">{data.replaceTransferButton}</Notification>
                            </>
                        ) : data.replaceTransferButton === "На рассмотрении" ? (
                            <>
                                <Notification type="coordination">{data.replaceTransferButton}</Notification>
                            </>
                        ) : null}
                    </span>
                </div>
                <div className={styles.item_text}>за рабочий день {dataItem.forDay}</div>
            </div>
            {visibleEdit ? (
                <SlideScreen altitudeLevel="full" titleText="Перенос отгула" onClose={() => setVisibleEdit(false)}>
                    <div className={styles.item_edit}>
                        <div>
                            <InputTitle titleText="Выберите новую дату отгула" />
                            <Notification type="info" icon="info">
                                {`Используйте отгул в течение 3-х месяцев от даты работы в выходной день ${data?.forDay}`}
                            </Notification>
                            <InputCalendar
                                maxDate={
                                    data?.maxDateTransfer
                                        ? new Date(
                                              Number(data?.maxDateTransfer?.split(".")[2]),
                                              Number(data?.maxDateTransfer?.split(".")[1]) - 1,
                                              Number(data?.maxDateTransfer?.split(".")[0])
                                          )
                                        : undefined
                                }
                                minDate={
                                    data?.minDateTransfer
                                        ? new Date(
                                              Number(data?.minDateTransfer?.split(".")[2]),
                                              Number(data?.minDateTransfer?.split(".")[1]) - 1,
                                              Number(data?.minDateTransfer?.split(".")[0])
                                          )
                                        : undefined
                                }
                                errorMessage={error}
                                style={{ marginTop: "12px" }}
                                titleText="Дата отгула"
                                type="dropdown_small"
                                titleTextSlide="Новая дата отгула"
                                clearError={clearErrorAll}
                                editData={editItem}
                                name="transferDay"
                                value={dataItem.transferDay?.replace(".202", ".2")}
                            />
                            {/* <InputTextArea type="area" titleText="Комментарий" editData={editItem} name="comment" placeholder="Укажите причину" /> */}
                        </div>
                        <Button load={load || loadLocal} disabled={dataItem.transferDay == oldDay ? true : false} type="save_not_margin" onClick={() => buffEdit()}>
                            Сохранить
                        </Button>
                    </div>
                </SlideScreen>
            ) : null}
        </>
    );
};

export default TimeOffItem;
