import React, { ReactElement } from "react";
import styles from "./index.module.scss";

export interface ICardDetails {
    textIcon?: string;
    text?: string;
    sum?: number;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CardDetails = (props: ICardDetails): ReactElement => {
    const { text, onClick = () => null, textIcon, sum } = props;
    return (
        <div onClick={onClick} className={styles.card}>
            <div className={styles.card_container}>
                <div className={styles["card_text-icon"]}>{textIcon}</div>
                <div className={styles.card_text}>{text}</div>
            </div>
            <div className={styles.card_sum}>{sum?.toFixed(2)}&#8381;</div>
        </div>
    );
};

export default CardDetails;
