import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../../../index.module.scss";
import { NavigationContext } from "../../../../../template";
import SlideScreen from "../../../../../components/SlideScreen";
import Button from "../../../../../components/Button";
import VacationCheck from "../../../components/VacationCheck";
import { InputCalendar, InputNumIMask } from "../../../../../components/Inputs";
import { IOverlayseVacation, IPaidVacNull } from "../../../../../typec/IVacations";
import { VacOfSubItemName } from "../../../../DocumentRequest/componets/VacOfSubItem";
import Notification from "../../../../../components/Notification";
import word from "../../../../../shared/helpers/word";
import clsx from "clsx";

const PaidVacNull = (props: IPaidVacNull): ReactElement => {
    const { loading = () => null, userVacation } = props;
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({
        valueStartDay: "",
        quantity: "",
        valueAmount: "",
        Date: "",
        finishDay: "",
    });
    const [error, setError] = useState({
        err: "",
        text: "",
    });
    const [screenCheck, setScreenCheck] = useState<boolean>(false);

    const [jsonOverlays, setJsonOverlayse] = useState<IOverlayseVacation>();

    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const editData = (element) => {
        setData((prevState) => ({
            ...prevState,
            ...element,
        }));
    };

    const putVac = () => {
        setLoad(true);
        fetch("/dev/vacation_vac_shed_new", {
            method: "PUT",
            body: JSON.stringify({
                Transfer: false,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                setLoad(false);
                if (json.res == true) {
                    loading();
                    setLoad(false);
                } else {
                }
            });
    };

    const postVacation = () => {
        if (Number(data.valueAmount) <= Number(data.quantity)) {
            // if (Number(data.valueAmount) % 7 == 0) {
            setLoad(true);
            fetch("/dev/vacation_vac_shed_new", {
                method: "POST",
                body: JSON.stringify({
                    Transfer: false,
                    Periods: [
                        {
                            StartDateOrigin: data.valueStartDay.replace(".2", ".202"),
                            StartDateTransfer: data.valueStartDay.replace(".2", ".202"),
                            NumberDays: data.valueAmount,
                        },
                    ],
                    Comment: "",
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setLoad(false);
                        editData({
                            Date: json.Date,
                            finishDay: json.KonetsDate,
                        });
                        setScreenCheck(true);
                    } else {
                        createError({ text: json.Error, err: json.ErrorCode });
                        setLoad(false);
                    }
                });
            // } else createError({ text: "Количество дней отпуска должно быть кратно 7", err: "ER_HRM01" });
        } else createError({ text: "Количество дней отпуска не может превышать доступное количество дней", err: "ER_HRM01" });
    };

    const dateNow = (): string => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        return `${day}.${month + 1}.${year}`;
    };

    useEffect(() => {
        if (/\d\d\.\d\d\.\d\d/.test(data.valueStartDay)) {
            fetch("/dev/vacation_leftover_new", {
                method: "POST",
                body: JSON.stringify({
                    DateLeftover: data.valueStartDay.replace(".2", ".202"),
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        editData({ quantity: `${json.DaysLeft}`.split(".")[0] });
                        setLoad(false);
                    } else {
                        editData({ quantity: "..." });
                    }
                });
        } else if (!data.valueStartDay) {
            fetch("/dev/vacation_leftover_new", {
                method: "POST",
                body: JSON.stringify({
                    DateLeftover: dateNow(),
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        editData({ quantity: `${json.DaysLeft}`.split(".")[0] });
                        setLoad(false);
                    } else {
                    }
                });
        } else {
            editData({ quantity: "..." });
        }
    }, [data.valueStartDay]);

    const cancelCheck = () => {
        setScreenCheck(false);
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        }).then((res) => res.json());
    };

    useEffect(() => {
        if (context.userdata.overlays_show) {
            fetch("/dev/vacation_list_new", {
                method: "PUT",
                body: JSON.stringify({
                    Periods: [{ StartDate: data.valueStartDay.replace(".2", ".202"), NumberDays: data.valueAmount }],
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        console.log(json);
                        setJsonOverlayse(json);
                    }
                });
        }
    }, [data.valueStartDay, data.valueAmount]);

    const formattedDateParts = (date) => {
        const dateParts = date.split(".").map((part) => (part.startsWith("0") ? part.slice(1) : part));
        return dateParts;
    };

    const minDate = formattedDateParts(userVacation?.min_date_vacation);
    const maxDate = formattedDateParts(userVacation?.max_date_vacation);

    return (
        <>
            {!screenCheck ? (
                <>
                    <div className={clsx(styles["hero_container_vacations"])}>
                        <p className={styles.main_heading}>
                            Заявление на <br /> оплачиваемый отпуск
                        </p>
                        {!userVacation?.allowed_vacation ? (
                            <Notification type="info" icon="info">
                                Заявление на&nbsp;оплачиваемый отпуск вы&nbsp;сможете поставить не&nbsp;раньше {userVacation?.allowed_vacation_date}
                            </Notification>
                        ) : (
                            <>
                                <Notification style={{ margin: "-8px 0px 8px 0px" }} type="info" icon="info">
                                    Отпуск сверх утвержденного графика отпусков можно оформить после отпусков по графику. Подать заявление можно не ранее чем за 21 день и не
                                    позднее чем за 15 дней до дня желаемого начала отпуска
                                </Notification>
                                <div className={styles.null_count_text}>
                                    На {/\d\d\.\d\d\.\d\d/.test(data.valueStartDay) ? data.valueStartDay : "сегодня"} доступно дней: <a>{data.quantity}</a>
                                </div>
                                <div className={styles.null_container}>
                                    <InputCalendar
                                        // defaultValue={new Date(templateYear, templateMonth, templateDay + 14)}
                                        // minDate={new Date(templateYear, templateMonth, templateDay + 14)}
                                        // maxDate={new Date(templateYear + 1, 11, 31)}
                                        defaultValue={new Date(Number(minDate[2]), Number(minDate[1]) - 1, Number(minDate[0]))}
                                        minDate={new Date(Number(minDate[2]), Number(minDate[1]) - 1, Number(minDate[0]))}
                                        maxDate={new Date(Number(maxDate[2]), Number(maxDate[1]) - 1, Number(maxDate[0]))}
                                        value={data.valueStartDay}
                                        clearError={clearError}
                                        errorMessage={
                                            error.err == "ER_HRM12" ||
                                            error.err == "ER_HRM03" ||
                                            error.err == "ER_HRM13" ||
                                            error.err == "ER_HRM14" ||
                                            error.err == "ER_HRM15" ||
                                            error.err == "ER_HRM21" ||
                                            error.err == "ER_HRM22"
                                                ? error.text
                                                : ""
                                        }
                                        dateNow={false}
                                        editVacation={editData}
                                        type="dropdown_small"
                                        titleText="Дата начала"
                                        key="InpCalendarDismissal"
                                        titleTextSlide="Дата начала отпуска"
                                        name="valueStartDay"
                                        placeholder="ДД.ММ.ГГ"
                                    />
                                    <InputNumIMask
                                        editData={editData}
                                        value={data.valueAmount}
                                        style={{ marginBottom: "24px" }}
                                        type="num"
                                        titleText="Кол-во дней"
                                        clearError={clearError}
                                        errorMessage={
                                            error.err == "ER_HRM01" ||
                                            error.err == "ER_HRM02" ||
                                            error.err == "ER_HRM23" ||
                                            error.err == "ER_HRM24" ||
                                            error.err == "ER_HRM03" ||
                                            error.err == "ER_HRM13" ||
                                            error.err == "ER_HRM14" ||
                                            error.err == "ER_HRM15" ||
                                            error.err == "ER_HRM21" ||
                                            error.err == "ER_HRM22"
                                                ? error.text
                                                : ""
                                        }
                                        // placeholder="7 или 14"
                                        placeholder="Введите дни"
                                    />
                                </div>
                            </>
                        )}
                        {context.userdata.overlays_show && data.valueAmount && data.valueStartDay ? (
                            <>
                                {jsonOverlays?.Overlays && jsonOverlays?.Overlays?.length > 0 ? (
                                    <>
                                        <Notification type="info" icon="info">
                                            Посмотрите, с кем из коллег пересекается ваш отпуск, при необходимости отредактируйте даты.
                                        </Notification>
                                        <div className={styles.edit_container_list}>
                                            {jsonOverlays?.Overlays?.map((el) => (
                                                <VacOfSubItemName name={el.Name} startDays={el.StartDate} konetsDays={el.KonetsDate} numberDays={`${el.NumberDays}`} />
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <Notification type="info" icon="info">
                                        Ваш отпуск не пересекается с коллегами из отдела!
                                    </Notification>
                                )}
                            </>
                        ) : null}
                        {userVacation?.allowed_vacation ? (
                            <SlideScreen altitudeLevel="service">
                                <Button load={load} type="save_not_margin" onClick={postVacation}>
                                    Сформировать заявление
                                </Button>
                            </SlideScreen>
                        ) : null}
                    </div>
                </>
            ) : (
                <>
                    <VacationCheck
                        nameStaff={context.userdata.name}
                        position={context.userdata.job_name}
                        subdivision={context.userdata.subdiv_name}
                        supervisor={context.userdata.boss_name}
                        statementDay={data.Date}
                        finishDay={""}
                        startDay={""}
                        quantityDay={data.valueAmount}
                        typeVacation="Оплачиваемый отпуск"
                        periodArr={[
                            `С ${data.valueStartDay?.replace(".2", ".202")} по ${data.finishDay}, ${data.valueAmount} ${word(Number(data.valueAmount), ["день", "дня", "дней"])}`,
                        ]}
                        disableDetails
                    />
                    <SlideScreen altitudeLevel="service">
                        <Button load={load} type="save_not_margin" onClick={putVac}>
                            Отправить на согласование
                        </Button>
                        <Button type="cancel_grey" onClick={cancelCheck}>
                            Отмена
                        </Button>
                    </SlideScreen>
                </>
            )}
        </>
    );
};

export default PaidVacNull;
