import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../../components/Navigation";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/Notification";
import CardNameExp from "../CardNameExp";
import ExpensesDetails from "../ExpensesDetails";
import getFirstUpperCase from "../../../../shared/helpers/capitalizedSentence";
import { IExpensesForMonth } from "../../../../typec/IExpensesForAddServices";

const ExpensesForMonth = (props: IExpensesForMonth): ReactElement => {
    const { data, prev = () => null } = props;
    const context = useContext(NavigationContext);
    const [openDetail, setOpenDetail] = useState<number | null>(null);

    const navigate = useNavigate();
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };
    const closeDetail = () => {
        setOpenDetail(null);
    };

    return (
        <>
            {typeof openDetail === "object" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain} />
                    <div className={styles["main"]}>
                        <p className={styles["main_title"]}>{data?.Month}</p>
                        <Notification style={{ marginTop: "11px" }} icon="info" type="info">
                            <div>
                                <p className={styles.main_ntf_text}>Для детализации расходов нажмите на интересующий вид расходов.</p>
                                {data?.Comment ? <p className={styles.main_ntf_text_bold}>{getFirstUpperCase(data.Comment.toLowerCase())}</p> : null}
                            </div>
                        </Notification>
                        <div>
                            {data?.Expanses?.map((element, index) => (
                                <CardNameExp key={`cardForMonth_${element.Name}_${index}`} text={element.Name} sum={element.Sum} onClick={() => setOpenDetail(index)} />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <ExpensesDetails comment={data?.Comment} title={data?.Month} data={data?.Expanses[openDetail]} prev={closeDetail} />
                </>
            )}
        </>
    );
};

export default ExpensesForMonth;
