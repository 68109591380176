import React from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

const monthsCases = {
    nominative: [
        // Именительный падеж (кто? что?)
        "январь",
        "февраль",
        "март",
        "апрель",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "декабрь",
    ],
    genitive: [
        // Родительный падеж (кого? чего?)
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ],
    dative: [
        // Дательный падеж (кому? чему?)
        "январю",
        "февралю",
        "марту",
        "апрелю",
        "маю",
        "июню",
        "июлю",
        "августу",
        "сентябрю",
        "октябрю",
        "ноябрю",
        "декабрю",
    ],
    accusative: [
        // Винительный падеж (кого? что?)
        "январь",
        "февраль",
        "март",
        "апрель",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "декабрь",
    ],
    instrumental: [
        // Творительный падеж (кем? чем?)
        "январём",
        "февралём",
        "мартом",
        "апрелем",
        "маем",
        "июнем",
        "июлем",
        "августом",
        "сентябрём",
        "октябрём",
        "ноябрём",
        "декабрём",
    ],
    prepositional: [
        // Предложный падеж (о ком? о чём?)
        "январе",
        "феврале",
        "марте",
        "апреле",
        "мае",
        "июне",
        "июле",
        "августе",
        "сентябре",
        "октябре",
        "ноябре",
        "декабре",
    ],
};

const Month = (props) => {
    const { how, caseName, type, monthName = null } = props;
    let monthIndex;

    if (monthName) {
        monthIndex = monthsCases.nominative.indexOf(monthName.toLowerCase());
        if (how === "current") {
            monthIndex = monthIndex;
        } else if (how === "next") {
            monthIndex = (monthIndex + 1) % 12;
        } else if (how === "prev") {
            monthIndex = (monthIndex - 1 + 12) % 12;
        }
    } else {
        const currentMonthIndex = new Date().getMonth();
        if (how === "current") {
            monthIndex = currentMonthIndex;
        } else if (how === "next") {
            monthIndex = (currentMonthIndex + 1) % 12;
        } else if (how === "prev") {
            monthIndex = (currentMonthIndex - 1 + 12) % 12;
        }
    }

    const monthInCase = monthsCases[caseName][monthIndex];

    return <span className={clsx(styles[type === "bold" ? "bold" : ""])}>{monthInCase}</span>;
};
export default Month;
