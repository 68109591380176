import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import CheckShell from "../CheckShell";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import Loader from "../../../../components/Loader";
import AddCheck from "../AddCheck";
import SetCheck from "../SetCheck";
import getDateStringMonth from "../../../../shared/helpers/getDateStringMonth";
import Employee from "../Employee";
import { ICompensation, IDataCompTrip } from "../../../../typec/IAdvanceReport";

const CompensationTrip = (props: ICompensation): ReactElement => {
    const { prev = () => null, data, getLoad = () => null, costTypes } = props;
    const [screen, setScreen] = useState<"main" | "addCheck" | "setCheck" | "employee">("main");
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const [dataTrip, setDataTrip] = useState<IDataCompTrip | undefined>(data);
    const [indexCheck, setIndexCheck] = useState<number>(0);

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const getMainLoad = () => {
        getLoad();
        setScreen("main");
    };

    return (
        <>
            {/* load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) :  */}
            {screen === "main" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main"]}>
                        <div>
                            <p className={styles["main_title"]}>Служебная поездка</p>
                            <p className={styles["main_sub_title"]}>Проверьте информацию о служебной поездке и добавьте чеки</p>
                            <Notification type="info" icon="info" style={{ marginTop: "15px" }}>
                                Служебная поездка с {getDateStringMonth(data?.StartDate)} по {getDateStringMonth(data?.KonetsDate)}
                            </Notification>
                            {dataTrip?.Checks.map((element, index) => (
                                <CheckShell
                                    key={`checkShell_compensation_${element.VidZatrat}_${index}`}
                                    onClick={() => {
                                        setIndexCheck(index);
                                        setScreen("setCheck");
                                    }}
                                    date={element.Date}
                                    name={element.VidZatrat}
                                    price={element.Sum.toString()}
                                />
                            ))}
                            <Button onClick={() => setScreen("addCheck")} icon="plus-interface" type="add_vacation" style={{ marginTop: "15px" }}>
                                Добавить чек
                            </Button>
                        </div>
                        {dataTrip?.Checks ? (
                            <Button onClick={() => setScreen("employee")} style={{ margin: "15px 0 0 0" }} type="save_not_margin">
                                Сформировать заявление
                            </Button>
                        ) : null}
                    </div>
                </>
            ) : screen === "addCheck" ? (
                <>
                    <AddCheck costTypes={costTypes} addCheck={getMainLoad} prev={() => setScreen("main")} tripID={dataTrip?.ID} appID={dataTrip?.IDApp} />
                </>
            ) : screen === "setCheck" ? (
                <>
                    <SetCheck costTypes={costTypes} setCheck={getMainLoad} data={dataTrip?.Checks[indexCheck]} appID={dataTrip?.IDApp} prev={() => setScreen("main")} />
                </>
            ) : (
                <>
                    <Employee data={dataTrip?.Checks[indexCheck]} appID={dataTrip?.IDApp} prev={() => setScreen("main")} />
                </>
            )}
        </>
    );
};

export default CompensationTrip;
