import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { NavigationContext } from "../../../template";
import Loader from "../../../components/Loader";
import Navigation from "../../../components/Navigation";
import Notification from "../../../components/Notification";
import CityList from "../components/CityList";
import TimeItem from "../components/TimeItem";
import OpenStop from "../components/OpenStop";
import { useDebounce } from "../../../shared/helpers/useDebounce";
import { InputSearch } from "../../../components/Inputs/inputSearch";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import { IBusDataLocation } from "../../../typec/IBus";

const BusSchedule = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadScreen, setLoadScreen] = useState(false);
    const [screens, setScreen] = useState(0);
    const [data, setData] = useState<any>({
        city: [],
        time: { id: "", name: "", type: "" },
        districts: [],
    });

    const [error, setError] = useState({
        ErrorCode: "",
        Error: "",
    });

    const context = useContext(NavigationContext);
    const navigate = useNavigate();

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const prev = () => {
        if (screens == 0) {
            navigate("/");
        } else {
            setScreen(screens - 1);
        }
    };

    const [jsonData, setJsonData] = useState<IBusDataLocation>();

    const next = () => {
        setScreen(screens + 1);
    };

    const [place, setPlace] = useState(false);

    useEffect(() => {
        if (load) {
            setLoad(false);
            fetch(`/dev/vacation_transport_new/?all_in_one=""`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    setLoad(true);
                    setJsonData(json);
                });
        }
    }, []);

    const handleCity = (value) => {
        setData({
            ...data,
            city: value,
        });
    };

    const handleTime = (value) => {
        setData({
            ...data,
            time: { id: value?.id, name: value?.name, type: value?.type },
            districts: value?.districts,
        });
    };

    const arrivalDepartureText =
        data.time.type === "arrival" ? data.time?.name?.replace("Прибытие", "Прибытие на работу") : data.time?.name?.replace("Отправление", "Отправление с работы");

    const [search, setSearch] = useState("");

    const [typeSearch, setTypeSearch] = useState<boolean>(true);

    const switchTypeSearch = () => {
        setTypeSearch(!typeSearch);
        setSearch("");
    };

    const changeSearchHandler = useDebounce((value) => {
        if (value.length >= 1) {
            setSearch(value || "");
        } else if (value.length == 0) {
            setSearch("");
        }
    }, 250);

    const changeSearch = (e) => {
        changeSearchHandler(e.target.value);
    };

    return (
        <>
            {!load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.dis}>
                        <p className={styles.dis_title}>{screens === 0 ? "Расписание автобусов" : data.city?.Name}</p>
                        {screens == 0 && (
                            <>
                                <div>
                                    <Notification type="info" icon="info">
                                        Выберите город
                                    </Notification>
                                    <div className={styles.list}>
                                        <CityList load={load} handleCity={handleCity} setLoad={setLoad} next={next} data={jsonData?.Cities}></CityList>
                                    </div>
                                </div>
                            </>
                        )}
                        {screens == 1 && (
                            <>
                                <div>
                                    <Notification type="info" icon="info">
                                        Выберите необходимое время
                                    </Notification>
                                    <>
                                        {data?.city?.TimesArrival &&
                                            data?.city?.TimesArrival.map((item) => (
                                                <TimeItem next={next} handleTime={handleTime} type={"arrival"} item={item} key={item.ID}></TimeItem>
                                            ))}
                                    </>
                                    <>
                                        {data?.city?.TimesDeparture &&
                                            data?.city?.TimesDeparture.map((item) => (
                                                <TimeItem next={next} handleTime={handleTime} type={"departure"} item={item} key={item.ID}></TimeItem>
                                            ))}
                                    </>
                                </div>
                            </>
                        )}
                        {screens == 2 && (
                            <>
                                <div>
                                    <Notification type="info" icon="info">
                                        {place ? "Найдите удобное направление" : "Остановки могут меняться"}
                                    </Notification>
                                    <p className={styles.title}>{arrivalDepartureText}</p>
                                    {!typeSearch ? (
                                        <div className={styles.search}>
                                            <InputSearch onChange={changeSearch} value={search} name="place" placeholder="Улица, остановка" type={"search"} />
                                        </div>
                                    ) : null}
                                    <div>
                                        <OpenStop next={next} districts={data.districts} setPlace={setPlace} type={"view"} typeSearch={typeSearch} searchValue={search} />
                                    </div>
                                    <SlideScreen altitudeLevel="service">
                                        <Button type="cancel_grey" onClick={switchTypeSearch}>
                                            {typeSearch ? "Поиск остановок" : "Список автобусов"}
                                        </Button>
                                    </SlideScreen>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default BusSchedule;
