import React, { ReactElement, useState, useContext, useEffect } from "react";
import Button from "../../../../../components/Button";
import styles from "./../index.module.scss";
import Loader from "../../../../../components/Loader";
import { InputDropDown, InputCalendar } from "../../../../../components/Inputs";
import { InputNumIMask } from "../../../../../components/Inputs";
import { VacationNext } from "../../index";
import { useDebounce } from "../../../../../shared/helpers/useDebounce";
import Notification from "../../../../../components/Notification";
import { VacOfSubItemNameGrafik } from "../../../../DocumentRequest/componets/VacOfSubItem";
import { NavigationContext } from "../../../../../template";
export interface INextYears {
    actionElement?: () => void;
    reasons: any;
    error?: any;
    clearError?: any;
    minDate?: Date;
    valueStartDay?: any;
    editVacation?: any;
    load?: any;
    valueAmount?: any;
    postVacation?: any;
    setShowChange?: any;
    periodFinish?: any;
    periodFinishEndDate?: any;
    editData?: any;
    editDataType?: any;
    inputCount?: any;
    setInputCount?: any;
    create?: (el: any) => any;
    setVacations?: any;
}
const VacationNew = (props: INextYears): ReactElement => {
    const { valueStartDay = "", clearError, load } = props;
    const { actionElement = () => null, reasons } = props;
    const Next = useContext(VacationNext);
    const [counterDay, setCounterDay] = useState(0);
    const [numberkolvo, setnumberkolvo] = useState(false);
    const [numberkolvoOst, setnumberkolvoOst] = useState(false);
    const templateYear = Number(`${new Date().getFullYear()}`);
    const contextOverlaysShow = useContext(NavigationContext);
    const [err, setErr] = useState("");
    const [loader, setLoad] = useState(false);
    const [userData, setData] = useState({
        dataStart: "",
        type: "",
        kolvo: "",
        Name: "",
        ID: "",
        inputCount: "1",
    });
    const [jsonOverlays, setJsonOverlayse] = useState<any>();
    const editData = (key, element) => {
        // changeSearchHandler(element.target.value);
        setData({
            ...userData,
            [key]: element,
        });
    };
    const editDataCount = (element) => {
        setData({
            ...userData,
            inputCount: element,
        });
    };
    const editDataType = (key, element) => {
        setData({
            ...userData,
            Name: element,
        });
        clearError();
    };
    const changeSubmit = useDebounce((userData) => {
        if (userData.Name !== "" && userData.ID !== "" && userData.dataStart !== "" && userData.inputCount !== "") {
            setCounterDay(-1);
            setnumberkolvo(false);
            setnumberkolvoOst(false);
            if (Number(userData.inputCount) < 30)
                fetch("/dev/vacation_vac_check_new", {
                    method: "POST",
                    body: JSON.stringify({
                        Comment: "",
                        ID: userData.ID,
                        StartDate: userData.dataStart.replace(".2", ".202"),
                        // NumberDays: userData.inputCount,
                        NumberDays: 1,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.res) {
                            setnumberkolvo(true);
                            setLoad(false);
                            props.setVacations(true);
                            setCounterDay(json.Ostatok);
                            setErr("");
                        } else {
                            // Заявление не сформировано
                            setErr(json.Error);
                            setLoad(false);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            // пересечение снизу
            if (contextOverlaysShow.userdata.overlays_show === true)
                fetch("/dev/vacation_schedule_new_overlays", {
                    method: "POST",
                    body: JSON.stringify({
                        StartDate: userData.dataStart.replace(".2", ".202"),
                        NumberDays: userData.inputCount,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.res) {
                            console.log(json);
                            setnumberkolvoOst(true);
                            setLoad(false);
                            setJsonOverlayse(json);
                            props.setVacations(true);
                            setErr("");
                        } else {
                            // Заявление не сформировано
                            setErr(json.Error);
                            console.log(json);
                            setLoad(false);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            ``;
        }
    }, 800);
    useEffect(() => {
        changeSubmit(userData);
    }, [userData]);
    const periodFinishEndDate = () => {
        setLoad(true);
        setErr("");
        fetch("/dev/vacation_cancel_new", {
            method: "PUT",
            body: JSON.stringify({
                Comment: "",
                ID: userData.ID,
                StartDate: userData.dataStart.replace(".2", ".202"),
                NumberDays: userData.inputCount,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    Next.create({
                        ID: userData.ID,
                        KonetsDate: json.KonetsDate,
                        NumberDays: userData.inputCount,
                        StartDate: userData.dataStart.replace(".2", ".202"),
                        Name: userData.Name,
                    });
                    props.setShowChange(false);
                } else {
                    // Заявление не сформировано
                    setErr(json.Error);
                    setLoad(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        ``;
    };
    if (userData.Name === "Отпуск по инвалидности") {
        userData.ID = "af3d2a4e-e1d2-11e8-80bc-00155d545503";
    }
    if (userData.Name === "Отпуск за вредность") {
        userData.ID = "9cc33f5a-a154-11e9-80bf-00155d9b230c";
    }
    if (userData.Name === "Северный отпуск") {
        userData.ID = "95c39a82-01c2-11e8-80c6-00155d545500";
    }
    if (userData.Name === "Основной отпуск") {
        userData.ID = "4e9b9a52-5773-11e7-80d7-40f2e9a19d93";
    }

    return (
        <>
            {loader ? (
                <>
                    <Loader></Loader>
                </>
            ) : null}
            <p className={styles.second_title}>Выберите вид отпуска и дату начала. Количество дней отпуска не может превышать доступные дни.</p>
            <div className={styles.edit_container}>
                <InputDropDown
                    key="InpDropDownYearsNext"
                    value={userData.Name === "Основной" ? "Основной отпуск" : userData.Name === "Северный" ? "Северный отпуск" : userData.Name}
                    editData={editDataType}
                    name="cause"
                    items={reasons.available_vacations.map((item) => (item.Name === "Основной" ? "Основной отпуск" : item.Name === "Северный" ? "Северный отпуск" : item.Name))}
                    icon="icon_drop_down"
                    titleText="Вид отпуска"
                />
                {numberkolvo ? (
                    <>
                        <div className={styles.count_text}>
                            Доступные дни: <a>{counterDay}</a>
                        </div>
                    </>
                ) : (
                    <div className={styles.null_count_text}></div>
                )}
                <div className={styles.edit_vac}>
                    <div className={styles.flex_calendar}>
                        <InputCalendar
                            editData={editData}
                            name="dataStart"
                            type="dropdown"
                            titleText="Начало периода"
                            titleTextSlide="Введите месяц и год"
                            key="InpCalendarDismissal"
                            value={userData.dataStart.replace(".2", ".202")}
                            icon="Calendar-icon"
                            placeholder="ДД.ММ.ГГГГ"
                            maxDate={new Date(templateYear + 1, 11, 31)}
                            minDate={new Date(templateYear, 12, 1)}
                        />
                    </div>
                    <div className={styles.flex_input}>
                        <p className={styles.kolvo}>Кол-во дней</p>
                        <InputNumIMask onChange={(event) => editDataCount(event.target.value)} placeholder="Введите дни" type="num" />
                    </div>
                </div>
                <div className={styles.error}>{err}</div>
                {numberkolvoOst && contextOverlaysShow ? (
                    <>
                        <div className={styles.top}>
                            {jsonOverlays?.Overlays?.length > 0 ? (
                                <Notification type="info" icon="info">
                                    Посмотрите, с кем из коллег пересекается ваш отпуск, при необходимости отредактируйте даты.
                                </Notification>
                            ) : null}
                        </div>
                        <div className={styles.periodArrIntersects}>
                            {jsonOverlays?.Overlays?.map((el, index) => (
                                <VacOfSubItemNameGrafik
                                    name={el.Name}
                                    DolzhnostName={el.DolzhnostName}
                                    startDays={el.StartDate}
                                    konetsDays={el.KonetsDate}
                                    numberDays={`${el.NumberDays}`}
                                />
                            ))}
                            <div className={styles.margin}></div>
                        </div>
                    </>
                ) : (
                    <div className={styles.null_count_text}></div>
                )}
                <Button
                    type="save"
                    load={load}
                    disabled={
                        userData.Name == "" ||
                        userData.ID == "" ||
                        userData.dataStart == "" ||
                        userData.inputCount == "" ||
                        counterDay < 0 ||
                        userData.inputCount.length > 2 ||
                        userData.inputCount.length < 1
                    }
                    onClick={periodFinishEndDate}
                >
                    Добавить отпуск
                </Button>
                <div>&nbsp;</div>
            </div>
        </>
    );
};

export default VacationNew;
