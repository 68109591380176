import React, { ReactElement } from "react";
import CityItem from "../CityItem";

const CityList = (props): ReactElement => {
    const { data, handleCity, load, setLoad, next } = props;
    return (
        <>
            {data?.map((list, index) => (
                <CityItem setData={handleCity} load={load} setLoad={setLoad} next={next} current={list} />
            ))}
        </>
    );
};

export default CityList;
