import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import VacScheduleItem from "../componets/VacScheduleItem";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { IVacationShedule } from "../../../typec/IDocumentRequest";

const VacationSchedule = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [vacData, setData] = useState<IVacationShedule>();
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_requests_new", {
                method: "POST",
                body: JSON.stringify({
                    Year: `${new Date().getFullYear()}`,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    setLoad(false);
                    setData(json);
                });
        }
    });

    const isVacNull = (): boolean => {
        let bool = false;
        const schedule = vacData?.Schedule;
        if (`${schedule}` == "") {
            bool = true;
        }
        return bool;
    };
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.doc}>
                        <p className={styles.doc_title}>Мой график отпусков</p>
                        {isVacNull() ? (
                            <Notification icon="info" type="info">
                                У вас пока нет оформленных <br /> отпусков. Возможность просмотра отпуска появится после успешного оформления.
                            </Notification>
                        ) : (
                            <>
                                {vacData?.Schedule?.map((element, index) => (
                                    <VacScheduleItem
                                        konetsDate={element.KonetsDate}
                                        startDate={element.StartDate}
                                        numberDays={element.NumberDays}
                                        typeVacationName={element.TypeVacationName}
                                        status={element.Status}
                                        key={`vacSchedule_${index}`}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default VacationSchedule;
