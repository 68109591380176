import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import Button from "../../../../components/Button";

export interface ICard {
    name?: string;
    date?: string;
    price?: string;
    onClick?: () => void;
}

const CheckShell = (props: ICard): ReactElement => {
    const { onClick = () => null, date, name, price } = props;
    return (
        <div className={styles.checkSh}>
            <div className={styles.checkSh_data}>
                <div className={styles.checkSh_data_box}>
                    <div className={styles.checkSh_data_box_name}>{name}</div>
                    <div className={styles.checkSh_data_box_date}>{date}</div>
                </div>
                <div className={styles.checkSh_data_price}>{price?.replace(".", ",")}&#8381;</div>
            </div>
            <Button icon="change" type="change_not_vw" onClick={onClick}>
                Изменить данные
            </Button>
        </div>
    );
};

export default CheckShell;
