import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import DynamicIcon from "../../../../components/DynamicIcon";

export interface ICard {
    icon?: string;
    text?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Card = (props: ICard): ReactElement => {
    const { icon, text, onClick = () => null } = props;
    return (
        <div onClick={onClick} className={styles.card}>
            {icon ? (
                <div className={styles.card_icon}>
                    <DynamicIcon name={icon} />
                </div>
            ) : null}
            <div className={styles.card_text}>{text}</div>
        </div>
    );
};

export default Card;
