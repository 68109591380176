import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Filter from "../../../icons/filter.svg";
import FiltrComponent, { IFilterCheckboxes } from "../FilterComponent";

const MenuFiltr = (props) => {
    const [isElementVisible, setElementVisible] = useState(false);
    const { menu } = props;
    const handleToggle = () => {
        setElementVisible(!isElementVisible);
    };

    const [checkboxes, setCheckboxes] = useState<IFilterCheckboxes>([
        { label: "Завершено", isSelected: false, filtr: "completed" },
        { label: "В работе", isSelected: true, filtr: "active" },
        { label: "Отказано или отменено", isSelected: false, filtr: "cancelled" },
    ]);
    const updateFilter = (element: IFilterCheckboxes) => {
        let reset = 0;
        let countChecboxes = 0;
        element.map((element) => {
            countChecboxes++;
            if (element.isSelected == false) reset++;
        });
        if (reset == countChecboxes) {
            setCheckboxes([
                { label: "Завершено", isSelected: false, filtr: "completed" },
                { label: "В работе", isSelected: true, filtr: "active" },
                { label: "Отказано или отменено", isSelected: false, filtr: "cancelled" },
            ]);
        } else setCheckboxes(element);
    };

    useEffect(() => {
        if (isElementVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isElementVisible]);

    return (
        <>
            <div className={styles.menuFlex}>
                {props.isApprovePage ? (
                    <div className={styles.main}>
                        {props.menu.title}Заявления<br></br> на согласование
                    </div>
                ) : (
                    <div className={styles.main}>{props.menu.title}Мои заявления</div>
                )}
                <div className={styles.filter} onClick={handleToggle}>
                    <div className={styles.filtrSVG}>
                        <Filter />
                    </div>
                    Фильтр
                </div>
            </div>
            <div className={styles.Filtrs}>
                {isElementVisible && (
                    <FiltrComponent
                        updateFilter={updateFilter}
                        filterData={checkboxes}
                        isElementVisible={isElementVisible}
                        close={handleToggle}
                        filterAction={props.filterAction}
                        props={props.jsonData}
                    ></FiltrComponent>
                )}
            </div>
        </>
    );
};

export default MenuFiltr;
