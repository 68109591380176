import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import CheckShell from "../CheckShell";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import { InputDropDown } from "../../../../components/Inputs/InputDropDown";
import { InputRadio } from "../../../../components/Inputs/InputRadio";
import NewPhotoButtonLimit from "../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import { InputCalendar, InputDate, InputNumIMask, InputText, InputTextArea } from "../../../../components/Inputs";
import CardCheck from "../CardCheck";
import Loader from "../../../../components/Loader";
import SlideScreen from "../../../../components/SlideScreen";
import { IDataCheck } from "../../../../typec/IAdvanceReport";
import { InputError } from "../../../../components/Inputs";

export interface ISetCheck {
    prev?: () => void;
    data?;
    appID: string | undefined;
}

const Employee = (props: ISetCheck): ReactElement => {
    const { prev = () => null, data } = props;
    const [dataCheck, setDataCheck] = useState<IDataCheck>(data);
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const createError = (str) => {
        setError(str);
    };
    const clearErrorInter = () => {
        setError("");
    };
    const [load, setLoad] = useState(false);
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };
    const EmployeeSubmit = (app_id) => {
        clearErrorInter();
        setLoad(true);
        fetch("/dev/vacation_send_for_approval", {
            method: "POST",
            body: JSON.stringify({
                IDApp: props.appID,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    console.log(json);
                    goToMain();
                    setLoad(false);
                } else {
                    console.log(json);
                    createError(json.Error);
                    setLoad(false);
                }
            });
    };
    const Submit = () => {
        EmployeeSubmit(props.appID);
    };
    console.log(dataCheck);
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main"]}>
                        <p className={styles["main_title"]}>Авансовый отчет</p>
                        <Notification type="info" icon="info" style={{ marginTop: "8px" }}>
                            Проверьте введённые данные и отправьте заявку на согласование.
                        </Notification>
                        {error ? <InputError titleText={error} /> : null}
                        <p className={styles["main_sub_title_margin"]}>ФИО сотрудника</p>
                        <p className={styles["main_sub_text"]}>{context.userdata.name}</p>
                        <p className={styles["main_sub_title_margin"]}>Затраты в авансовый отчет</p>
                        <p className={styles["main_sub_text"]}></p>
                        {dataCheck?.Sostav.map((element, index) => (
                            <>
                                <p className={styles["main_sub_text"]}>
                                    {element.Name}, {element.Sum}&#8381;
                                </p>
                            </>
                        ))}
                        <p className={styles["main_sub_title_margin"]}>Итоговая сумма к возмещению</p>
                        <p className={styles["main_sub_text_end"]}>{dataCheck?.Sum}&#8381;</p>
                        {/* <Button type="save_not_margin">Отправить на согласование</Button> */}
                        {/* <Button type="cancel_white">Отмена</Button> */}
                        <SlideScreen altitudeLevel="service">
                            <Button onClick={Submit} type="save_not_margin">
                                Отправить на согласование
                            </Button>
                            <Button onClick={prev} type="cancel_white">
                                Отмена
                            </Button>
                        </SlideScreen>
                    </div>
                </>
            )}
        </>
    );
};

export default Employee;
