import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../../components/Navigation";
import Card from "../Card";
import Loader from "../../../../components/Loader";
import Notification from "../../../../components/Notification";
import CompensationTrip from "../CompensationTrip";
import { IBusinessTrip, ICostType, IDataOneTrip, IDataTrip } from "../../../../typec/IAdvanceReport";

const BusinessTrip = (props: IBusinessTrip): ReactElement => {
    const { prev = () => null, currentID, dataTrips } = props;
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [dataTrip, setDataTrip] = useState<IDataTrip | undefined>(dataTrips);
    const [dataOneTrip, setDataOneTrip] = useState<IDataOneTrip>();
    const [openTrip, setOpenTrip] = useState<string | null>(currentID ? currentID : null);
    const [costType, setCostType] = useState<ICostType>();
    const navigate = useNavigate();

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const getLoad = () => {
        setLoad(true);
    };

    useEffect(() => {
        if (load && typeof openTrip === "object") {
            fetch(`/dev/vacation_business_trips_new`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setDataTrip(json);
                        fetch("/dev/vacation_checks_new", {
                            method: "GET",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                        })
                            .then((res) => res.json())
                            .then((json) => {
                                if (json.res) {
                                    setCostType(json);
                                }
                                setLoad(false);
                            });
                    }
                });
        } else if (load && typeof openTrip === "string") {
            fetch(`/dev/vacation_business_trips_new?trip_id=${openTrip}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setDataOneTrip(json);
                    }
                    setLoad(false);
                });
        }
    }, [load, openTrip]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : typeof openTrip == "object" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles["main"]}>
                        <p className={styles["main_title"]}>Служебная поездка</p>
                        <p className={styles["main_sub_title"]}>Выберите служебную поездку, по которой хотите добавить затраты</p>
                        {dataTrip?.trips?.length ? (
                            <div className={styles["main_card-list"]}>
                                {dataTrip?.trips?.map((element, index) => (
                                    <Card
                                        key={`card_in_bnssTrip_${index}_${element}`}
                                        icon="ruble_bold_not_wh"
                                        text={`Служебная поездка с ${element.StartDate.split(".").slice(0, 2).join(".")} по ${element.KonetsDate.split(".").slice(0, 2).join(".")}`}
                                        onClick={() => {
                                            setOpenTrip(element.ID);
                                            setLoad(true);
                                        }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <Notification type="info" icon="info" style={{ marginTop: "8px" }}>
                                Данные по служебным поездкам не найдены. Проверьте заявку на командировку в Битриксе.
                            </Notification>
                        )}
                    </div>
                </>
            ) : typeof openTrip == "string" ? (
                <>
                    <CompensationTrip costTypes={costType} data={dataOneTrip?.trips} prev={() => setOpenTrip(null)} getLoad={getLoad} />
                </>
            ) : null}
        </>
    );
};

export default BusinessTrip;
