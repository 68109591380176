import React, { ReactElement, useContext } from "react";
import styles from "./index.module.scss";
import { InputBill, InputError, InputMonthYear } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import SlideScreen from "../../../../components/SlideScreen";
import Notification from "../../../../components/Notification";
import VacationCheck2 from "../../../Vacation/components/VacationCheck/index2";
import { NavigationContext } from "../../../../template";
import clsx from "clsx";

export interface IBankCardAndCheck {
    dataChild: any;
    viewFinish: boolean;
    getChildNames: (element) => string;
    cardSelect: boolean | undefined;
    getXXXNumCard: (element) => string;
    getAllPhotos: (element) => void;
    error: any;
    loadBtn: any;
    postDataChild: (bank_card_number, bank_card_end_date, actual_children, previous_children, previous_children_files) => void;
    setViewFinish: (element) => void;
    setCardSelect: (element) => void;
    setViewBankSlider: (element) => void;
    viewBankSlider: boolean;
    editBank: (key, element) => void;
    testCardMIR: (element, bool?, cardSelect?) => void;
    clearError: () => void;
    viewLeave: number;
    postBIR: (bank_card_number, bank_card_end_date, start_date, konets_date, list_number) => void;
}

const BankCardAndCheck = (props: IBankCardAndCheck): ReactElement => {
    const {
        dataChild,
        cardSelect,
        error,
        getAllPhotos,
        getChildNames,
        getXXXNumCard,
        loadBtn,
        postDataChild,
        setViewFinish,
        viewFinish,
        clearError,
        editBank,
        setCardSelect,
        setViewBankSlider,
        testCardMIR,
        viewBankSlider,
        viewLeave,
        postBIR,
    } = props;
    const context = useContext(NavigationContext);
    return (
        <>
            {viewFinish ? (
                // финишный экран отправки заявления
                <>
                    <VacationCheck2
                        nameStaff={context.userdata.name}
                        nameChildren={viewLeave === 3 ? getChildNames(dataChild.actualChildren) : undefined}
                        cardNumber={
                            cardSelect || (cardSelect == undefined && context.userdata.bank_card)
                                ? `${getXXXNumCard(context.userdata?.bank_card_number)},  ${getXXXNumCard(context.userdata?.bank_card_end_date)}`
                                : `${getXXXNumCard(dataChild.bankCardNumber)},  ${dataChild.bankCardEndDate}`
                        }
                        sickLeave={
                            viewLeave === 1
                                ? `№${dataChild.numberReport} с ${dataChild.startDay?.replace(".2", ".202")} по ${dataChild.finishDay?.replace(".2", ".202")}`
                                : undefined
                        }
                        disableNo={true}
                        typeVacation={viewLeave === 3 ? "отпуск по уходу за ребенком" : viewLeave === 1 ? "отпуск по беременности и родам" : ""}
                        statementDay={new Date().toLocaleString("ru-RU").split(",")[0]}
                        photoArr={viewLeave === 3 ? getAllPhotos(dataChild) : null}
                    />
                    <SlideScreen altitudeLevel="service">
                        {error?.finish ? <InputError titleText={error?.finish} /> : null}
                        <Button
                            load={loadBtn?.finish}
                            type="save_not_margin"
                            onClick={() =>
                                viewLeave === 3
                                    ? postDataChild(
                                          dataChild.bankCardNumber,
                                          dataChild.bankCardEndDate,
                                          dataChild.actualChildren,
                                          dataChild.previousChildren,
                                          dataChild.previousChildrenFiles
                                      )
                                    : viewLeave === 1
                                    ? postBIR(dataChild.bankCardNumber, dataChild.bankCardEndDate, dataChild.startDay, dataChild.finishDay, dataChild.numberReport)
                                    : null
                            }
                        >
                            Отправить заявление
                        </Button>
                        <Button type="cancel_grey" onClick={() => setViewFinish(false)}>
                            Отмена
                        </Button>
                    </SlideScreen>
                </>
            ) : (
                <>
                    <div className={styles.hero}>
                        {viewLeave === 3 ? (
                            <p className={styles.hero_main_heading}>Отпуск по уходу за ребенком до&nbsp;1,5&nbsp;лет</p>
                        ) : (
                            <p className={styles.hero_main_heading}>Отпуск по беременности и родам</p>
                        )}
                        <div className={styles.hero_bank}>
                            {context.userdata?.bank_card ? (
                                <>
                                    <div>
                                        <Notification icon="info" type="info" style={{ marginTop: "0px" }}>
                                            Мы нашли у вас карту МИР, если хотите получать пособие по уходу за ребенком на другую карту, то добавьте новую карту МИР
                                        </Notification>
                                        <div
                                            className={clsx(
                                                styles["hero_bank_card"],
                                                cardSelect && dataChild.bankCardEndDate.length > 0 && dataChild.bankCardNumber.length > 0 && styles["hero_bank_card_focus"]
                                            )}
                                            onClick={() => {
                                                dataChild.bankCardEndDate.length > 0 && dataChild.bankCardNumber.length > 0 ? setCardSelect(true) : null;
                                            }}
                                        >
                                            <p className={styles.hero_bank_card_num}>{getXXXNumCard(context.userdata?.bank_card_number)}</p>
                                            <p className={styles.hero_bank_card_date}>{context.userdata?.bank_card_end_date}</p>
                                        </div>
                                        {dataChild.bankCardEndDate.length > 0 && dataChild.bankCardNumber.length > 0 ? (
                                            <div
                                                className={clsx(styles["hero_bank_card_new"], !cardSelect && styles["hero_bank_card_focus"])}
                                                onClick={() => {
                                                    setCardSelect(false);
                                                }}
                                            >
                                                <div className={styles.hero_bank_card_new_container}>
                                                    <p className={styles.hero_bank_card_num}>{getXXXNumCard(dataChild.bankCardNumber)}</p>
                                                    <p className={styles.hero_bank_card_date}>{dataChild.bankCardEndDate}</p>
                                                </div>
                                                <Button style={{ marginTop: "22px" }} type="change_not_vw" onClick={() => setViewBankSlider(true)}>
                                                    Изменить данные карты
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button
                                                style={{ marginTop: "16px" }}
                                                type="cancel_grey"
                                                onClick={() => {
                                                    setViewBankSlider(true);
                                                    // setCardSelect(false);
                                                }}
                                            >
                                                Добавить новую карту
                                            </Button>
                                        )}
                                    </div>
                                    <div>
                                        <Button
                                            load={loadBtn?.bank}
                                            type="save_not_margin"
                                            onClick={() => {
                                                testCardMIR(dataChild.bankCardNumber, false);
                                            }}
                                        >
                                            Сформировать заявление
                                        </Button>
                                    </div>
                                    {viewBankSlider ? (
                                        // добавление и изменение данных карты
                                        <>
                                            <SlideScreen
                                                altitudeLevel="calendar"
                                                onClose={() => {
                                                    setViewBankSlider(false);
                                                    editBank("bankCardNumber", "");
                                                    editBank("bankCardEndDate", "");
                                                }}
                                                titleText="Карта МИР"
                                            >
                                                <div className={styles.hero_bank_slider}>
                                                    <div>
                                                        <InputBill
                                                            editData={editBank}
                                                            name="bankCardNumber"
                                                            style={{ marginTop: "12px" }}
                                                            type="cardNum"
                                                            titleText="Номер карты МИР"
                                                            placeholder="4444 4444 4444 4444"
                                                            value={dataChild.bankCardNumber}
                                                            errorMessage={error?.bank}
                                                            clearError={clearError}
                                                        />
                                                        <InputMonthYear
                                                            editData={editBank}
                                                            name="bankCardEndDate"
                                                            style={{ marginTop: "12px" }}
                                                            type="fullString"
                                                            titleText="Срок действия карты"
                                                            value={dataChild.bankCardEndDate}
                                                            separator="/"
                                                            clearError={clearError}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Button
                                                            load={loadBtn?.bank}
                                                            style={{ marginTop: "24px", marginBottom: "0px" }}
                                                            type="save_not_margin"
                                                            onClick={() => testCardMIR(dataChild.bankCardNumber, true, false)}
                                                            disabled={dataChild.bankCardEndDate.length > 0 && dataChild.bankCardNumber.length > 0 ? false : true}
                                                        >
                                                            Сохранить карту
                                                        </Button>
                                                    </div>
                                                </div>
                                            </SlideScreen>
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div>
                                        <Notification icon="info" type="info" style={{ marginTop: "0px" }}>
                                            Введите данные карты МИР, на которую вам будут приходить пособие по уходу за ребенком
                                        </Notification>
                                        <InputBill
                                            value={dataChild.bankCardNumber}
                                            editData={editBank}
                                            name="bankCardNumber"
                                            style={{ marginTop: "12px" }}
                                            type="cardNum"
                                            titleText="Номер карты МИР"
                                            placeholder="4444 4444 4444 4444"
                                            errorMessage={error?.bank}
                                            clearError={clearError}
                                        />
                                        <InputMonthYear
                                            value={dataChild.bankCardEndDate}
                                            editData={editBank}
                                            name="bankCardEndDate"
                                            style={{ marginTop: "12px" }}
                                            type="fullString"
                                            titleText="Срок действия карты"
                                            separator="/"
                                            clearError={clearError}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            load={loadBtn?.bank}
                                            type="save_not_margin"
                                            onClick={() => {
                                                testCardMIR(dataChild.bankCardNumber, true);
                                            }}
                                        >
                                            Сформировать заявление
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default BankCardAndCheck;
