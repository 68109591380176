import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Arrow from "./../../../../icons/icon_drop_down.svg";
import clsx from "clsx";

const OpenStop = (props): ReactElement => {
    const { next, type, screens, current, currentFolder, districts, handleStop, direction, typeSearch, searchValue } = props;

    const [open, setOpen] = useState<string>(currentFolder || "");

    useEffect(() => {
        setOpen(currentFolder);
    }, [typeSearch]);

    const handleOpen = (value) => {
        if (!open) {
            setOpen(value);
        } else {
            if (value === open) {
                setOpen("");
            } else {
                setOpen(value);
            }
        }
    };

    const changePlace = (StopID, BusID, FolderID = "") => {
        if (type === "change") {
            if (screens !== 4) {
                next();
            }
            handleStop({ StopID: StopID, BusID: BusID, FolderID: FolderID });
        }
    };

    const [list, setList] = useState<any>();
    const [listSearch, setListSearch] = useState<any>();

    const extractTimesDepartureWithStops = () => {
        const arrayStop: any = [];

        districts?.forEach((city) => {
            city.Stops.forEach((stop: any) => {
                arrayStop.push({ ...stop, FolderID: city.ID });
            });
        });
        return arrayStop;
    };

    useEffect(() => {
        setList(extractTimesDepartureWithStops());
        setListSearch(extractTimesDepartureWithStops());
    }, []);

    useEffect(() => {
        if (searchValue.length !== 0 && list && listSearch) {
            const cleanedSearchValue = searchValue.replace(/[\.\-\(\)\s]/g, "").toLowerCase();
            const newListAfterSearch = list?.filter((item) => {
                const cleanedName = item.Name.replace(/[\.\-\(\)]/g, "")
                    .toLowerCase()
                    .split(" ");
                return cleanedName.some((el) => el.startsWith(cleanedSearchValue));
            });
            setListSearch(newListAfterSearch);
        } else {
            if (list) {
                setListSearch(list);
            }
        }
    }, [searchValue]);

    return (
        <>
            {typeSearch ? (
                <>
                    {districts?.map((item, index) => (
                        <div key={`Open-stop-${item.ID}-folder-${index}`}>
                            <button
                                className={clsx(styles["item"], open === item.ID ? styles["item_open"] : "")}
                                onClick={() => handleOpen(item.ID)}
                                key={`Open-stop-${item.ID}-folder-btn`}
                            >
                                <span>{item?.Name}</span>
                                <div className={clsx(styles["arrow"], open === item.ID ? styles["arrow_up"] : styles["arrow_down"])}>
                                    <Arrow />
                                </div>
                            </button>
                            {item?.Stops?.map((itemStop) => (
                                <>
                                    {open === item.ID ? (
                                        <div className={styles.list}>
                                            <button
                                                className={clsx(styles["list_item"], current === itemStop?.ID && styles["list_item_current"])}
                                                onClick={() => changePlace(itemStop?.ID, itemStop?.BusID, item?.ID)}
                                                key={`Open-stop-${itemStop.ID}-folder-btn-item`}
                                            >
                                                <span className={styles.list_item_place}>{itemStop?.Name}</span>
                                                {direction !== "departure" && itemStop?.Time !== "0:00" ? <span className={styles.list_item_time}>{itemStop?.Time}</span> : null}
                                            </button>
                                        </div>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {listSearch?.length !== 0 ? (
                        <>
                            {listSearch?.map((itemStop) => (
                                <div className={styles.list}>
                                    <button
                                        className={clsx(styles["list_item"], current === itemStop?.ID && styles["list_item_current"])}
                                        onClick={() => changePlace(itemStop?.ID, itemStop?.BusID, itemStop?.FolderID)}
                                        key={`Open-stop-${itemStop.ID}-folder-btn-item-all`}
                                    >
                                        <span className={styles.list_item_place}>{itemStop?.Name}</span>
                                        {direction !== "departure" && itemStop?.Time !== "0:00" ? <span className={styles.list_item_time}>{itemStop?.Time}</span> : null}
                                    </button>
                                </div>
                            ))}
                        </>
                    ) : (
                        <p className={styles.message}>Мы не нашли остановку, измените название.</p>
                    )}
                </>
            )}
        </>
    );
};

export default OpenStop;
