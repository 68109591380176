import React, { ReactElement, useContext, useState } from "react";
import styles from "../../../index.module.scss";
import { NavigationContext } from "../../../../../template";
import SlideScreen from "../../../../../components/SlideScreen";
import Button from "../../../../../components/Button";
import VacationCheck from "../../../components/VacationCheck";
import { InputCalendar, InputNumIMask, InputText } from "../../../../../components/Inputs";
import { IVacExpenseNull } from "../../../../../typec/IVacations";
import word from "../../../../../shared/helpers/word";

const VacExpenseNull = (props: IVacExpenseNull): ReactElement => {
    const { loading } = props;
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({
        valueCause: "По семейным обстоятельствам",
        valueStartDay: "",
        quantity: "",
        valueAmount: "",
        Date: "",
        finishDay: "",
    });
    const [error, setError] = useState({
        err: "",
        text: "",
    });
    const [screenCheck, setScreenCheck] = useState<boolean>(false);

    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const editData = (element) => {
        setData((prevState) => ({
            ...prevState,
            ...element,
        }));
    };

    const putVac = () => {
        setLoad(true);
        fetch("/dev/vacation_vac_own_new", {
            method: "PUT",
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    loading();
                    setLoad(false);
                }
            });
    };

    const postVacation = () => {
        if (/\d\d\.\d\d\.\d\d/.test(data.valueStartDay)) {
            if (Number(data.valueAmount) > 0) {
                setLoad(true);
                fetch("/dev/vacation_vac_own_new", {
                    method: "POST",
                    body: JSON.stringify({
                        StartDate: data.valueStartDay.replace(".2", ".202"),
                        NumberDays: data.valueAmount,
                        Comment: data.valueCause,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.res == true) {
                            setLoad(false);
                            editData({
                                Date: json.Date,
                                finishDay: json.KonetsDate,
                            });
                            setScreenCheck(true);
                        } else {
                            createError({ text: json.Error, err: json.ErrorCode });
                            setLoad(false);
                        }
                    });
            } else createError({ text: "Заполните поле количества дней отпуска", err: "ER_HRM01" });
        } else createError({ text: "Заполните поля даты начала отпуска", err: "ER_HRM12" });
    };

    const cancelCheck = () => {
        setScreenCheck(false);
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        }).then((res) => res.json());
    };

    return (
        <>
            {!screenCheck ? (
                <>
                    <div className={styles.hero_container_vacations}>
                        <p className={styles.main_heading}>
                            Заявление на отпуск за <br /> свой счет
                        </p>
                        <InputText clearError={clearError} editVacation={editData} titleText="Причина отпуска" type="fullString" value={data.valueCause} />
                        <div className={styles.null_container}>
                            <InputCalendar
                                minDate={new Date(templateYear, templateMonth, templateDay)}
                                maxDate={new Date(templateYear + 1, 11, 31)}
                                value={data.valueStartDay}
                                clearError={clearError}
                                errorMessage={
                                    error.err == "ER_HRM12" ||
                                    error.err == "ER_HRM03" ||
                                    error.err == "ER_HRM13" ||
                                    error.err == "ER_HRM14" ||
                                    error.err == "ER_HRM15" ||
                                    error.err == "ER_HRM21" ||
                                    error.err == "ER_HRM22"
                                        ? error.text
                                        : ""
                                }
                                dateNow={false}
                                editVacation={editData}
                                type="dropdown_small"
                                titleText="Дата начала"
                                key="InpCalendarDismissal"
                                titleTextSlide="Дата начала отпуска"
                                name="valueStartDay"
                                placeholder="ДД.ММ.ГГ"
                                style={{ marginTop: "24px" }}
                            />
                            <InputNumIMask
                                editData={editData}
                                value={data.valueAmount}
                                type="num"
                                titleText="Кол-во дней"
                                clearError={clearError}
                                errorMessage={
                                    error.err == "ER_HRM01" ||
                                    error.err == "ER_HRM02" ||
                                    error.err == "ER_HRM23" ||
                                    error.err == "ER_HRM24" ||
                                    error.err == "ER_HRM03" ||
                                    error.err == "ER_HRM13" ||
                                    error.err == "ER_HRM14" ||
                                    error.err == "ER_HRM15" ||
                                    error.err == "ER_HRM21" ||
                                    error.err == "ER_HRM22"
                                        ? error.text
                                        : ""
                                }
                                placeholder="Введите дни"
                                style={{ marginTop: "24px" }}
                            />
                        </div>
                        <SlideScreen altitudeLevel="service">
                            <Button load={load} type="save_not_margin" onClick={postVacation}>
                                Сформировать заявление
                            </Button>
                        </SlideScreen>
                    </div>
                </>
            ) : (
                <>
                    <VacationCheck
                        nameStaff={context.userdata.name}
                        position={context.userdata.job_name}
                        subdivision={context.userdata.subdiv_name}
                        supervisor={context.userdata.boss_name}
                        statementDay={data.Date}
                        finishDay={""}
                        startDay={""}
                        quantityDay={data.valueAmount}
                        typeVacation="Отпуск за свой счет"
                        periodArr={[
                            `С ${data.valueStartDay?.replace(".2", ".202")} по ${data.finishDay}, ${data.valueAmount} ${word(Number(data.valueAmount), ["день", "дня", "дней"])}`,
                        ]}
                        disableDetails
                    />
                    <SlideScreen altitudeLevel="service">
                        <Button load={load} type="save_not_margin" onClick={putVac}>
                            Отправить на согласование
                        </Button>
                        <Button type="cancel_grey" onClick={cancelCheck}>
                            Отмена
                        </Button>
                    </SlideScreen>
                </>
            )}
        </>
    );
};

export default VacExpenseNull;
