import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router";
import Loader from "../../../../components/Loader";
import City from "./../../../../icons/city.svg";

const CityItem = (props): ReactElement => {
    const { setData, current, load, next } = props;

    const open = (value) => {
        next();
        setData(value);
    };

    return (
        <>
            {!load ? <Loader /> : null}
            <div onClick={() => open(current)} className={styles.menu}>
                <div className={styles.menu_listok}>
                    <City />
                </div>
                <div className={styles.block}>{current?.Name}</div>
            </div>
        </>
    );
};

export default CityItem;
