import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router";
import VacationCheckList from "../../Vacation/components/VacationCheck/components/VacationCheckList";
import VacationCheck from "../../Vacation/components/VacationCheck";
import word from "../../../shared/helpers/word";
import VacationCheck2 from "../../Vacation/components/VacationCheck/index2";
import Cancel from "../Cancel";
import Submit from "../../Approve/Submit";
import Button from "../../../components/Button";
import DeleteUser from "../DeleteUser";
import { VacOfSubItemName } from "../../DocumentRequest/componets/VacOfSubItem";
import Loader from "../../../components/Loader";
import Delete from "../../../icons/Close_red.svg";
import { useSearchParams } from "react-router-dom";
import Employee from "../../AdvanceReport/components/Employee";

const ListItem = (props): ReactElement => {
    const [info, setInfo] = useState(false);
    const [load, setLoad] = useState(false);
    const [delUser, setDelUser] = useState(false);

    const arrTypeVac = ["Ежегодный оплачиваемый отпуск", "Северный", "Отпуск за свой счёт", "Дополнительный отпуск IT", "Перенос графика отпусков", "График отпусков"];

    const context = useContext(NavigationContext);
    const open = () => {
        setInfo(true);
    };
    const closeinfo = () => {
        setInfo(false);
    };
    const [data, setData] = useState({
        homeScreen: {
            cause: "",
            details: "",
            dateDismissal: "",
            dismBool: undefined,
        },
        questionsList: undefined,
        address: "",
    });
    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    const openUserDelete = () => {
        setDelUser(true);
    };
    const closeUserDelete = () => {
        setDelUser(false);
    };
    const [isElementVisible, setElementVisible] = useState(false);
    const handleToggle = () => {
        setElementVisible(!isElementVisible);
    };
    const [HasADFS, setHasADFS] = useState();
    const [jsonOverlays, setJsonOverlayse] = useState<any>();
    const period = props.current.Schedule?.filter((el) => el.Changed).map((el) => ({ StartDate: el.StartDateTransfer, NumberDays: el.NumberDaysTransfer }));
    const periodNew = props.current.NewSchedule?.filter((el) => el.Changed).map((el) => ({ StartDate: el.StartDateTransfer, NumberDays: el.NumberDaysTransfer }));
    const periodArrIntersects = () => {
        fetch("/dev/vacation_list_new", {
            method: "PUT",
            body: JSON.stringify({
                // Periods: props.current.StartDate == "01.01.1" ? period : [{ StartDate: props.current.StartDate, NumberDays: props.current.NumberDays }],
                Periods:
                    props.current.Type == "Увольнение" || props.current.Type == "Перенос отгула" || props.current.Type == "График отпусков"
                        ? [{ StartDate: "01.01.2000", NumberDays: "7" }]
                        : props.current.StartDate == "01.01.1"
                        ? period
                        : [{ StartDate: props.current.StartDate, NumberDays: props.current.NumberDays }],
                TGID: props.current.EmpTGID,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    console.log(json);
                    setJsonOverlayse(json);
                    setHasADFS(json.HasADFS);
                    console.log(HasADFS);
                }
            });
    };
    useEffect(() => {
        if (info) {
            periodArrIntersects();
        }
    }, [info]);
    const periodArr = () => {
        const getListPeriods = (data) => {
            const arrDate: string[] = [];
            const periods: any[] = [];

            data.Schedule.map((el) => {
                if (el.StartDate != el.StartDateTransfer || el.KonetsDate != el.KonetsDateTransfer) {
                    if (!arrDate.includes(el.StartDate)) {
                        arrDate.push(el.StartDate);
                        const a: string[] = [`<s>c ${el.StartDate} по ${el.KonetsDate}</s> `];
                        data.Schedule.map((element) => {
                            if (el.StartDate == element.StartDate && el.KonetsDate == element.KonetsDate) {
                                a.push(`</br>c ${element.StartDateTransfer} по ${element.KonetsDateTransfer}`, ` ${element.NumberDaysTransfer} дней`);
                            }
                        });
                        periods.push(a);
                    }
                }
            });
            return periods;
        };

        return getListPeriods(props.current);
    };

    const getCheckListPeriods = (list) => {
        const newList = list?.map((el) => {
            if (el.DateTransfer != "" && el.DateTransfer != el.Date) {
                return [`c ${el.Date} на ${el.DateTransfer}`];
            }
        });
        return newList;
    };
    const periodArrNew = () => {
        const periodNew = props.current.NewSchedule?.map((el) => {
            return [
                `<span style=font-weight:550;>${el.TypeVacationName}</span>
            </br>c ${el.StartDate} по ${el.KonetsDate}, ${el.NumberDays} ${word(Number(el.NumberDays), ["день", "дня", "дней"])}`,
            ];
        });
        return periodNew;
    };

    // функции и перменные связанные с вычетом на детей
    //--
    const [dataUser, setDataUser] = useState({
        commentUser: "",
        photos: [],
    });
    const [loadDeducation, setLoadDeducation] = useState(false);
    // const [loadBtnPost, setLoadBtnPost] = useState(false);
    const [errorDeducation, setErrorDeducation] = useState("");
    const [avansovogoOtcheta, serAvansovogoOtcheta] = useState("");
    const [loadBtn, setLoadBtn] = useState<any>();
    const [loadDelete, setLoadDelete] = useState(true);
    const closeDelete = () => {
        setLoadDelete(false);
    };

    const createLoadBtn = (key) => {
        setLoadBtn((prevState) => {
            return {
                ...prevState,
                [key]: true,
            };
        });
    };
    const clearLoadBtn = () => {
        setLoadBtn({});
    };

    const photoAdd = (element) => {
        setDataUser((prevState) => ({
            ...prevState,
            photos: element,
        }));
        setErrorDeducation("");
    };
    const editCommentUser = (key, element) => {
        setDataUser((prevState) => ({
            ...prevState,
            [key]: element,
        }));
        setErrorDeducation("");
    };

    const toDeducationFromChild = () => {
        navigate("/deductionForChildren");
    };

    // подтверждение оригиналов
    const confirmTheOriginals = (id_app, comment) => {
        createLoadBtn("aproveOrig");
        fetch("/dev/vacation_vychety_new", {
            method: "PUT",
            body: JSON.stringify({
                IDApp: id_app,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    prev();
                }
                clearLoadBtn();
            });
    };

    // отправка недостающих файлов
    const transferringMissingFiles = (id_app, screens, comment) => {
        // обработать ошибки
        const screensTrue = screens.map((el, index) => {
            return {
                Content: el.data,
                Name: `screen_${index}.${el.data.split(";")[0].split("/")[1]}`,
            };
        });
        createLoadBtn("missFiles");
        fetch("/dev/vacation_add_files_new", {
            method: "POST",
            body: JSON.stringify({
                IDApp: id_app,
                Screens: screensTrue,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    prev();
                } else {
                    console.log(json);
                    setErrorDeducation(json.Error);
                }
                clearLoadBtn();
            });
    };

    const cancelApplication = (id_app, comment = "") => {
        setLoadDeducation(true);
        createLoadBtn("cancelApp");
        fetch("/dev/vacation_cancel_new", {
            method: "POST",
            body: JSON.stringify({
                IDApp: id_app,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    goToMain();
                }
                setLoadDeducation(false);
                clearLoadBtn();
            });
    };
    //--

    //-- функции и переменные для ухода за ребенком
    const getChildNames = (element) => {
        return element.map((el) => {
            return [el.Name, ` ${el.Birthdate}`];
        });
    };
    //--

    const getXXXNumCard = (string) => {
        let res = "";
        let j = 0;
        if (string.includes("*")) {
            for (let i = 0; i < string.length; i++) {
                j++;
                if (i > 3 && i < 12) {
                    res += "x";
                } else res += string[i];
                if ((i + 1) % 4 == 0 && string[j] != " ") {
                    res += " ";
                    j--;
                }
            }
        } else {
            for (let i = 0; i < string.length; i++) {
                if (i != 9 && i > 4 && i < 14) {
                    res += "x";
                } else res += string[i];
            }
        }
        return res.trim();
    };

    const [, setSearchParam] = useSearchParams();
    const redirect = (forward_to: string, app_id: string) => {
        setSearchParam({ app_id: app_id, forward_to: forward_to });
    };

    useEffect(() => {
        if (props.current.IDApp == context.dataRedirect?.app_id) {
            open();
            context.clearDataRedirect();
        }
    }, []);
    const [localItems, setLocalItems] = useState(
        props?.current?.ChecksItems?.map((item, index) => ({
            id: index,
            ...item,
        }))
    );
    const [newItems, setNewItems] = useState([]);
    const [dataCheck, setDataCheck] = useState(props.current.TotalSum);
    const handleDelete = (id) => {
        const newItems = localItems.filter((item) => item.id !== id);
        setLocalItems(newItems);
        setNewItems(newItems);
        const Summa = newItems
            .reduce((sum, element) => {
                if (element) return parseFloat(`${sum}`) + parseFloat(`${element.Sum}`.replace(",", "."));
                else return sum;
            }, 0.0)
            .toFixed(2);
        setDataCheck(Summa);
    };

    console.log(jsonOverlays);

    return (
        <>
            <div onClick={open} className={styles.border}>
                <div className={styles.flex}>
                    <div className={styles.Otpysk}>{props.current.Type}</div>
                    {props.current.Type == "Вычет на детей" ? (
                        <>
                            {props.current.ApprDecl == "-1" ? (
                                <div className={props.current.StatusDisplay?.includes("Отказ") ? styles.status7 : styles.status2}>{props.current.StatusVychet}</div>
                            ) : null}
                            {props.current.ApprDecl == "1" ? <div className={styles.status}>{props.current.StatusVychet}</div> : null}
                            {props.current.ApprDecl == "0" ? <div className={styles.status3}>{props.current.StatusVychet}</div> : null}
                        </>
                    ) : props.current.Type == "Авансовый отчет" && !props.isApprovePage ? (
                        <>
                            {props.current.StatusVychet === "Черновик" ? (
                                <>
                                    <div className={styles.status9}>{props.current.StatusVychet}</div>
                                </>
                            ) : (
                                <>
                                    {props.current.ApprDecl == "-1" ? (
                                        <div className={props.current.StatusDisplay?.includes("Отказ") ? styles.status7 : styles.status2}>{props.current.StatusVychet}</div>
                                    ) : null}
                                    {props.current.ApprDecl == "1" ? <div className={styles.status}>{props.current.StatusVychet}</div> : null}
                                    {props.current.ApprDecl == "0" ? <div className={styles.status3}>{props.current.StatusVychet}</div> : null}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {props.current.ApprDecl == "-1" ? (
                                <div className={props.current.StatusDisplay?.includes("Отказ") ? styles.status7 : styles.status2}>{props.current.StatusDisplay}</div>
                            ) : null}
                            {props.current.ApprDecl == "1" ? <div className={styles.status}>{props.current.StatusDisplay}</div> : null}
                            {props.current.ApprDecl == "0" ? <div className={styles.status3}>{props.current.StatusDisplay}</div> : null}
                        </>
                    )}
                </div>
                {props.current.KonetsDate == "01.01.1" && props.current.Type == "Направление расчетных листов" ? (
                    <div className={styles.flex2}>
                        <div className={styles.date}>Способ получения: {props.current.SposobPolucheniya}</div>
                    </div>
                ) : null}
                {props.current.KonetsDate !== "01.01.1" || props.current.Type == "Заказ справки" || props.current.Type == "Изменение персональных данных" ? (
                    <div className={styles.flex2}>
                        <div className={styles.date}>
                            <div>
                                <div className={styles.name}>{props.current.EmpName}</div>
                            </div>
                        </div>

                        <div className={styles.line}>
                            {props.current.Type == "Заказ справки" ? (
                                <div className={styles.days23}>{props.current.VidSpravki}</div>
                            ) : props.current.Type == "Изменение персональных данных" ? (
                                <div className={styles.days23}>{props.current.VidPersonalnyhDannyh}</div>
                            ) : (
                                <div>
                                    {props.current.StartDate} - {props.current.KonetsDate}
                                </div>
                            )}

                            <div className={styles.days}>
                                {props.current.Type == "Заказ справки" || props.current.Type == "Изменение персональных данных"
                                    ? undefined
                                    : `${props.current.NumberDays} ${word(props.current.NumberDays, ["день", "дня", "дней"])}`}
                            </div>
                        </div>
                    </div>
                ) : null}
                {props.current.Type == "Перенос графика отпусков" ? (
                    <div className={styles.flex2}>
                        <div className={styles.date}>
                            {props.current.NumberDays == "1" ? (
                                <div>{props.current.Schedule}</div>
                            ) : (
                                <div>
                                    <div className={styles.name}>{props.current.EmpName}</div>
                                    <div>
                                        {props.current.Schedule.map((list) => (
                                            <>
                                                {list.StartDate !== list.StartDateTransfer || list.KonetsDate !== list.KonetsDateTransfer ? (
                                                    <>
                                                        <div className={styles.count}>
                                                            <div className={styles.days23}>
                                                                {list.StartDate} - {list.KonetsDate} <span>--&gt;</span>
                                                                <div>
                                                                    {list.StartDateTransfer} - {list.KonetsDateTransfer}
                                                                </div>
                                                            </div>
                                                            <div className={styles.daysVac}>
                                                                {list.NumberDaysTransfer} {word(list.NumberDaysTransfer, ["день", "дня", "дней"])}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
                {props.current.Type == "Перенос отгула" ? (
                    <div className={styles.flex2}>
                        <div className={styles.date}>
                            {props.current.NumberDays == "1" ? (
                                <div>{props.current.Schedule}</div>
                            ) : (
                                <div>
                                    <div className={styles.name}>{props.current.EmpName}</div>
                                    <div>
                                        {props.current.Dayoffs?.map((element) => (
                                            <div className={styles.count}>
                                                <div className={styles.days23}>
                                                    {element.Date} <span>--&gt;</span> {element.DateTransfer}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
                {props.current.Type == "График отпусков" ? (
                    <div className={styles.flex2}>
                        <div className={styles.date}>
                            {props.current.NumberDays == "1" ? (
                                <div>{props.current.NewSchedule}</div>
                            ) : (
                                <div>
                                    <div className={styles.name}>{props.current.EmpName}</div>
                                    <div>
                                        {props.current.NewSchedule.map((list) => (
                                            <>
                                                <div className={styles.count}>
                                                    <div className={styles.days23}>
                                                        {list.StartDate} - {list.KonetsDate}
                                                    </div>
                                                    <div className={styles.daysVac}>
                                                        {list.NumberDays} {word(list.NumberDays, ["день", "дня", "дней"])}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            {info ? (
                <div className={styles.info}>
                    <Navigation prev={closeinfo} goToMain={goToMain}></Navigation>
                    <div className={styles.statusInfo}>
                        {props.current.StatusVychet === "Черновик" ? (
                            <>
                                <div className={styles.status9}>{props.current.StatusVychet}</div>
                            </>
                        ) : (
                            <>
                                {props.current.ApprDecl == "-1" ? (
                                    <div className={props.current.StatusDisplay?.includes("Отказ") ? styles.status8 : styles.status5}>{props.current.Status}</div>
                                ) : null}
                                {props.current.ApprDecl == "1" ? <div className={styles.status4}>{props.current.Status}</div> : null}
                                {props.current.ApprDecl == "0" ? <div className={styles.status6}>{props.current.Status}</div> : null}
                            </>
                        )}
                    </div>
                    <div className={styles.block}>&nbsp;</div>
                    {props.isApprovePage ? (
                        <VacationCheck2
                            nameStaff={props.current.EmpName}
                            position={props.current.EmpJob}
                            subdivision={props.current.EmpSubdivision}
                            supervisor={props.current.EmpBoss}
                            statementDay={props.current.Date}
                            finishDay={props.current.KonetsDate}
                            startDay={props.current.StartDate}
                            quantityDay={props.current.NumberDays}
                            quantitySposob={props.current.SposobPolucheniya}
                            typeVacation={props.current.Type}
                            numberVacation={props.current.Number}
                            periodArr={periodArr()}
                            periodArrNew={periodArrNew()}
                            // commentary={props.current.Commentary}
                            dataUvolneniyaZhelayemaya={props.current.DataUvolneniyaZhelayemaya != "01.01.1" ? props.current.DataUvolneniyaZhelayemaya : undefined}
                            dataUvolneniya={props.current.DataUvolneniya != "01.01.1" ? props.current.DataUvolneniya : undefined}
                            status={props.current.Type == "Увольнение" ? props.current.Status : undefined}
                            prichinaUvolneniya={props.current.PrichinaUvolneniya}
                            prichinaUvolneniyaBoss={data.homeScreen.cause}
                            commentaryBoss={data.homeScreen.details}
                            dataUvolneniyaBoss={data.homeScreen.dateDismissal}
                            coordination={props.isApprovePage}
                            itemDetails={
                                arrTypeVac.includes(props.current.Type)
                                    ? props.current.NewSchedule.length > 0
                                        ? periodArrNew()
                                        : props.current.Schedule.length > 0
                                        ? periodArr()
                                        : [
                                              `с ${props.current.StartDate} по ${props.current.KonetsDate}, ${props.current.NumberDays} ${word(Number(props.current.NumberDays), [
                                                  "день",
                                                  "дня",
                                                  "дней",
                                              ])}`,
                                          ]
                                    : props.current.Type == "Заказ справки"
                                    ? [
                                          `${props.current.Type} "${props.current.VidSpravki}"`,
                                          props.current.PeriodSpravki ? `Период: ${props.current.PeriodSpravki}` : "",
                                          `Вид: ${props.current.SposobPolucheniyaSpravki}, ${props.current.KolichestvoEkzemplyarov} экз.`,
                                      ]
                                    : props.current.Type == "Изменение персональных данных"
                                    ? [
                                          `${props.current.VidPersonalnyhDannyh}`,
                                          props.current.PrichinaIzmeneniyaPersonalnyhDannyh !== "" ? `Причина: ${props.current.PrichinaIzmeneniyaPersonalnyhDannyh}` : null,
                                      ]
                                    : props.current.Type == "Направление расчетных листов"
                                    ? [`Способ получения: ${props.current.SposobPolucheniya}`]
                                    : props.current.Type == "Перечисление зарплаты на карту"
                                    ? [`Добавлены реквизиты карты для перечисления заработной платы`]
                                    : props.current.Type == "Перенос отгула"
                                    ? getCheckListPeriods(props.current.Dayoffs)
                                    : undefined
                            }
                        />
                    ) : (
                        // блок для вычетов на детей
                        <>
                            {props.current.Type == "Вычет на детей" ? (
                                <>
                                    {loadDeducation ? (
                                        <>
                                            <Loader></Loader>
                                        </>
                                    ) : (
                                        <div className={styles.info_deducation}>
                                            <VacationCheck2
                                                nameStaff={context.userdata.name}
                                                nameChildren={props.current.ChildName}
                                                position={""}
                                                subdivision={""}
                                                statementDay={props.current.Date}
                                                finishDay={""}
                                                startDay={""}
                                                quantityDay={""}
                                                disableVoid={true}
                                                typeVacation={"вычет на детей"}
                                                numberVacation={props.current.Number}
                                                coordination={props.isApprovePage}
                                                commentaryStaff={props.current.Commentary}
                                                itemDetails={props.current.StatusCommentary ? [`${props.current.StatusCommentary}`] : undefined}
                                                // photoArr={props.current.StatusVychet == "В работе" ? } // пока пустота
                                                photoAdd={props.current.StatusVychet == "Ожидание документов" ? photoAdd : undefined}
                                                photoConfirmation={false}
                                                writeComment={
                                                    props.current.StatusVychet == "Ожидание документов" || props.current.StatusVychet == "Ожидание решения" ? true : false
                                                }
                                                writeCommentEdit={editCommentUser}
                                                valueComment={dataUser.commentUser}
                                                nameEditItemComment="commentUser"
                                                photoError={errorDeducation}
                                            />
                                            <div className={styles.info_deducation_btn}>
                                                {props.current.StatusVychet?.includes("Отказ") || props.current.StatusVychet?.includes("Отмен") ? (
                                                    // блок при отмене или отказе вычета
                                                    <>
                                                        <Button type="save_not_margin" onClick={() => toDeducationFromChild()}>
                                                            Подать заново
                                                        </Button>
                                                    </>
                                                ) : props.current.StatusVychet == "Ожидание документов" ? (
                                                    // блок при ожидании документов
                                                    <>
                                                        <Button
                                                            type="save_not_margin"
                                                            load={loadBtn?.missFiles}
                                                            onClick={() => transferringMissingFiles(props.current.IDApp, dataUser.photos, dataUser.commentUser)}
                                                        >
                                                            Отправить фото недостающих документов
                                                        </Button>
                                                        <Button type="cancel_red" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                            Отмена
                                                        </Button>
                                                    </>
                                                ) : props.current.StatusVychet == "Ожидание решения" ? (
                                                    // блок при ожидании решения
                                                    <>
                                                        <Button
                                                            type="save_not_margin"
                                                            load={loadBtn?.aproveOrig}
                                                            onClick={() => confirmTheOriginals(props.current.IDApp, dataUser.commentUser)}
                                                        >
                                                            Оригиналы предоставлю
                                                        </Button>
                                                        <Button type="cancel_grey" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                            Отказаться от вычета
                                                        </Button>
                                                    </>
                                                ) : props.current.StatusVychet == "Ожидание оригиналов" ? (
                                                    // блок при ожидании оригиналов
                                                    <>
                                                        <Button type="cancel_grey" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                            Отказаться от вычета
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button type="cancel_red" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                            Отмена
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : props.current.Type == "Отпуск по уходу за ребенком" ? (
                                // блок для отпуска по уходу за ребенком
                                <>
                                    <div className={styles.info_deducation}>
                                        <VacationCheck2
                                            nameStaff={context.userdata.name}
                                            nameChildren={getChildNames(props.current.Children)}
                                            position={""}
                                            subdivision={""}
                                            statementDay={props.current.Date}
                                            finishDay={""}
                                            startDay={""}
                                            quantityDay={""}
                                            disableVoid={true}
                                            typeVacation={"отпуск по уходу за ребенком"}
                                            numberVacation={props.current.Number}
                                            coordination={props.isApprovePage}
                                            commentaryStaff={props.current.Commentary}
                                            itemDetails={props.current.StatusCommentary ? [`${props.current.StatusCommentary}`] : undefined}
                                            // photoArr={props.current.StatusVychet == "В работе" ? } // пока пустота
                                            photoAdd={props.current.StatusVychet == "Ожидание документов" ? photoAdd : undefined}
                                            photoConfirmation={false}
                                            writeComment={props.current.StatusVychet == "Ожидание документов" || props.current.StatusVychet == "Ожидание решения" ? true : false}
                                            writeCommentEdit={editCommentUser}
                                            valueComment={dataUser.commentUser}
                                            nameEditItemComment="commentUser"
                                            photoError={errorDeducation}
                                        />
                                        <div className={styles.info_deducation_btn}>
                                            {props.current.StatusVychet == "Ожидание документов" ? (
                                                // кнопка для отправки недостающих файлов
                                                <>
                                                    <Button
                                                        type="save_not_margin"
                                                        load={loadBtn?.missFiles}
                                                        onClick={() => transferringMissingFiles(props.current.IDApp, dataUser.photos, dataUser.commentUser)}
                                                    >
                                                        Отправить фото недостающих документов
                                                    </Button>
                                                    <Button type="cancel_red" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                        Отмена
                                                    </Button>
                                                </>
                                            ) : !props.current.StatusVychet?.includes("Отказ") || !props.current.StatusVychet?.includes("Отмен") ? (
                                                <Button type="cancel_red" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                    Отмена
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            ) : props.current.Type == "Отпуск по беременности и родам" ? (
                                // блок для отпуск по беременности и родам
                                <>
                                    <div className={styles.info_deducation}>
                                        <VacationCheck2
                                            nameStaff={context.userdata.name}
                                            cardNumber={`${getXXXNumCard(props.current.BankCardNumber)}, ${props.current.BankCardEndDate}`}
                                            sickLeave={`№${props.current.BIRListNumber} c ${props.current.BIRStartDate} по ${props.current.BIRKonetsDate}`}
                                            position={""}
                                            subdivision={""}
                                            statementDay={props.current.Date}
                                            finishDay={""}
                                            startDay={""}
                                            quantityDay={""}
                                            disableVoid={true}
                                            typeVacation={"отпуск по беременности и родам"}
                                            numberVacation={props.current.Number}
                                            coordination={props.isApprovePage}
                                            commentaryStaff={props.current.Commentary}
                                            itemDetails={props.current.StatusCommentary ? [`${props.current.StatusCommentary}`] : undefined}
                                        />
                                    </div>
                                </>
                            ) : props.current.Type == "Авансовый отчет" ? (
                                <>
                                    <div className={styles.info_deducation}>
                                        <div>
                                            <VacationCheck2
                                                nameStaff={context.userdata.name}
                                                position={""}
                                                subdivision={""}
                                                supervisor={context.userdata.boss_name}
                                                statementDay={props.current.Date}
                                                finishDay={""}
                                                startDay={""}
                                                quantityDay={""}
                                                commentary={props.current.Commentary}
                                                typeVacation={props.current.Type}
                                                numberVacation={props.current.Number}
                                                statusCommentary={props.current.StatusCommentary}
                                                status={props.current.Status}
                                                coordination={props.isApprovePage}
                                                disableVoid
                                            />
                                            <div style={{ marginTop: "16px" }} className={styles.periodArrIntersects}>
                                                <div>Подробная информация по затратам</div>
                                                {props.current.Checks ? <div className={styles.information}></div> : <div className={styles.informationBlock}></div>}
                                                {props.current.Checks?.map((el) => (
                                                    <>
                                                        <div className={styles.information}>
                                                            {el.VidZatrat}, {el.Sum}&#8381;
                                                        </div>
                                                    </>
                                                ))}
                                                <div>{jsonOverlays?.Checks?.length == 0 ? <div className={styles.information}>Позиций не найдено</div> : null}</div>
                                            </div>
                                        </div>
                                        <div className={styles.info_deducation_btn}>
                                            <>
                                                {!props.current.StatusVychet?.includes("Отказ") && !props.current.StatusVychet?.includes("Отмен") ? (
                                                    <>
                                                        {props.current.StatusVychet?.includes("Черновик") ? (
                                                            <Button
                                                                type="save_not_margin"
                                                                load={loadBtn?.missFiles}
                                                                onClick={() =>
                                                                    redirect("advanceReport", props.current.BusinessTripID ? props.current.BusinessTripID : props.current.IDApp)
                                                                }
                                                            >
                                                                Продолжить заполнение
                                                            </Button>
                                                        ) : null}
                                                        <Button type="cancel_red" load={loadBtn?.cancelApp} onClick={() => cancelApplication(props.current.IDApp)}>
                                                            Отменить
                                                        </Button>
                                                    </>
                                                ) : null}
                                            </>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <VacationCheck2
                                    nameStaff={context.userdata.name}
                                    position={""}
                                    subdivision={""}
                                    supervisor={context.userdata.boss_name}
                                    statementDay={props.current.Date}
                                    finishDay={""}
                                    startDay={""}
                                    quantityDay={""}
                                    dataUvolneniyaZhelayemaya={props.current.DataUvolneniyaZhelayemaya != "01.01.1" ? props.current.DataUvolneniyaZhelayemaya : undefined}
                                    dataUvolneniya={props.current.DataUvolneniya != "01.01.1" ? props.current.DataUvolneniya : undefined}
                                    commentary={props.current.Commentary}
                                    // quantitySposob={props.current.SposobPolucheniya}
                                    typeVacation={props.current.Type}
                                    numberVacation={props.current.Number}
                                    // periodArr={periodArr()}
                                    // kolichestvoEkzemplyarov={props.current.KolichestvoEkzemplyarov}
                                    mestoVydachiSpravki={props.current.MestoVydachiSpravki}
                                    opisanieMestaVydachiSpravki={props.current.OpisanieMestaVydachiSpravki}
                                    // periodSpravki={props.current.PeriodSpravki}
                                    // sposobPolucheniyaSpravki={props.current.SposobPolucheniyaSpravki}
                                    // vidSpravki={props.current.VidSpravki}
                                    statusCommentary={props.current.Type !== "Увольнение" ? props.current.StatusCommentary : ""}
                                    status={props.current.Status}
                                    coordination={props.isApprovePage}
                                    // prichinaIzmeneniyaPersonalnyhDannyh={props.current.PrichinaIzmeneniyaPersonalnyhDannyh}
                                    // vidPersonalnyhDannyh={props.current.VidPersonalnyhDannyh}
                                    itemDetails={
                                        arrTypeVac.includes(props.current.Type)
                                            ? props.current.NewSchedule.length > 0
                                                ? periodArrNew()
                                                : props.current.Schedule.length > 0
                                                ? periodArr()
                                                : [
                                                      `с ${props.current.StartDate} по ${props.current.KonetsDate}, ${props.current.NumberDays} ${word(
                                                          Number(props.current.NumberDays),
                                                          ["день", "дня", "дней"]
                                                      )}`,
                                                  ]
                                            : props.current.Type == "Заказ справки"
                                            ? [
                                                  `${props.current.Type} "${props.current.VidSpravki}"`,
                                                  props.current.PeriodSpravki ? `Период: ${props.current.PeriodSpravki}` : "",
                                                  `Вид: ${props.current.SposobPolucheniyaSpravki}, ${props.current.KolichestvoEkzemplyarov} экз.`,
                                              ]
                                            : props.current.Type == "Изменение персональных данных"
                                            ? [
                                                  `${props.current.Type},`,
                                                  `"${props.current.VidPersonalnyhDannyh}"`,
                                                  props.current.PrichinaIzmeneniyaPersonalnyhDannyh !== "" ? `Причина: ${props.current.PrichinaIzmeneniyaPersonalnyhDannyh}` : null,
                                              ]
                                            : props.current.Type == "Направление расчетных листов"
                                            ? [`Способ получения: ${props.current.SposobPolucheniya}`]
                                            : props.current.Type == "Перечисление зарплаты на карту"
                                            ? [`Добавлены реквизиты карты для перечисления заработной платы`]
                                            : props.current.Type == "Перенос отгула"
                                            ? getCheckListPeriods(props.current.Dayoffs)
                                            : undefined
                                    }
                                />
                            )}
                        </>
                    )}
                    {props.isApprovePage ? (
                        // руководитель
                        <div>
                            {props.current.Type !== "Авансовый отчет" ? null : (
                                <div className={styles.periodArrIntersects}>
                                    <div>Затраты в авансовый отчет</div>
                                    {props.current.ChecksItems ? <div className={styles.information}></div> : <div className={styles.informationBlock}></div>}
                                    <div>
                                        {/* <EmployeeDelete IDApp={props.current.IDApp} TotalSum={props.current.TotalSum} items={props.current.ChecksItems} /> */}
                                        {localItems.map((el) => (
                                            <div key={el.id}>
                                                <div className={styles.card_shell}>
                                                    <div className={styles.card}>
                                                        <div className={styles.card_box}>
                                                            <div className={styles.card_box_text}>{el.Name}</div>
                                                        </div>
                                                        <div className={styles.card_box_2}>
                                                            <div className={styles.card_box_price}>{el.Sum}&#8381;</div>
                                                            <div className={styles.card_icon} />
                                                        </div>
                                                    </div>
                                                    <div onClick={() => handleDelete(el.id)} className={styles.card_icon_delete}>
                                                        <Delete />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className={styles.item_container_title}>Итоговая сумма к возмещению</div>
                                        <div className={styles.item_container_info}>{dataCheck}&#8381;</div>
                                    </div>
                                    <div>{jsonOverlays?.Checks?.length == 0 ? <div className={styles.information}>Позиций не найдено</div> : null}</div>
                                    <div className={styles.margin}></div>
                                </div>
                            )}
                        </div>
                    ) : //сотрудник
                    // перенес (см. 670 строчку)
                    null}
                    {props.current.Type == "Перенос графика отпусков" ||
                    props.current.Type == "Ежегодный оплачиваемый отпуск" ||
                    // props.current.Type == "График отпусков" ||
                    (props.current.Type != "Увольнение" &&
                        props.current.Type != "Отпуск за свой счёт" &&
                        props.current.Type != "График отпусков" &&
                        props.current.Type != "Дополнительный отпуск IT" &&
                        props.current.Type != "Перечисление зарплаты на карту" &&
                        props.current.Type != "Перенос отгула" &&
                        props.current.Type != "Авансовый отчет" &&
                        props.current.Type != "Вычет на детей" &&
                        props.current.Type != "Отпуск по уходу за ребенком" &&
                        props.current.Type != "Отпуск по беременности и родам" &&
                        props.isApprovePage) ? (
                        <div className={styles.periodArrIntersects}>
                            <div>Пересечение отпусков</div>
                            {jsonOverlays?.Overlays ? <div className={styles.information}></div> : <div className={styles.informationBlock}></div>}
                            {jsonOverlays?.Overlays?.map((el, index) => (
                                <VacOfSubItemName
                                    key={`overlays_${props.current.IDApp}_i_${index}`}
                                    name={el.Name}
                                    startDays={el.StartDate}
                                    konetsDays={el.KonetsDate}
                                    numberDays={`${el.NumberDays}`}
                                />
                            ))}
                            <div>{jsonOverlays?.Overlays?.length == 0 ? <div className={styles.information}>Пересечений отпусков не найдено</div> : null}</div>
                            <div className={styles.margin}></div>
                        </div>
                    ) : null}
                    {props.current.Type == "Увольнение" && props.current.ApprDecl == "0" && props.isApprovePage ? (
                        <>
                            <div className={styles.boss} onClick={handleToggle}>
                                <Button type="cancel_grey">Заполнить часть руководителя</Button>
                            </div>
                            <Submit
                                getLoad={props.getLoad}
                                disable={
                                    props.current.Type == "Увольнение" ? (data.homeScreen.cause && data.homeScreen.dateDismissal && data.homeScreen.details ? false : true) : false
                                }
                                homeScreenData={data.homeScreen}
                                addData={editData}
                                newItems={newItems}
                                current={props.current}
                            ></Submit>
                        </>
                    ) : null}
                    {props.isApprovePage && props.current.ApprDecl == "0" ? (
                        <Submit
                            getLoad={props.getLoad}
                            disable={
                                props.current.Type == "Увольнение"
                                    ? data.homeScreen.cause && data.homeScreen.dateDismissal && data.homeScreen.details
                                        ? false
                                        : true
                                    : props.current.Type == "График отпусков" ||
                                      props.current.Type == "Дополнительный отпуск IT" ||
                                      props.current.Type == "Отпуск за свой счёт" ||
                                      props.current.Type == "Авансовый отчет"
                                    ? false
                                    : jsonOverlays?.res != undefined
                                    ? false
                                    : true
                            }
                            homeScreenData={data.homeScreen}
                            addData={editData}
                            current={props.current}
                            newItems={newItems}
                            type={props.current.Type}
                        ></Submit>
                    ) : (
                        <div></div>
                    )}
                    {/* <div>
                        {(props.current.Type !== "Дополнительный отпуск IT" && props.current.Completed == true) || props.current.Status == "Отменена" ? null : (
                            <Cancel setInfo={setInfo} current={props.current}></Cancel>
                        )}
                    </div> */}
                    {props.isApprovePage ? (
                        <></>
                    ) : (
                        <div>
                            {(props.current.Type !== "Дополнительный отпуск IT" && props.current.Completed == true) ||
                            props.current.StatusDisplay == "Отменено" ||
                            props.current.StatusDisplay == "Отказано" ||
                            props.current.Type == "Вычет на детей" ||
                            props.current.Type == "Отпуск по уходу за ребенком" ||
                            props.current.Type == "Авансовый отчет" ? null : (
                                <Cancel setInfo={setInfo} current={props.current}></Cancel>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <div></div>
                </div>
            )}
            {isElementVisible && (
                <DeleteUser setHasADFS={HasADFS} isApprovePage={props.isApprovePage} editData={editData} setElementVisible={setElementVisible} current={props.current}></DeleteUser>
            )}
        </>
    );
};

export default ListItem;
