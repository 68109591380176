import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Card from "../AdvanceReport/components/Card";
import ExpensesForMonth from "./components/ExpensesForMonth";
import { IDataServices } from "../../typec/IExpensesForAddServices";
import Notification from "../../components/Notification";

const ExpensesForAddServices = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [data, setData] = useState<IDataServices>();
    const [openMonth, setOpenMonth] = useState<number | null>(null);

    const navigate = useNavigate();
    const prev = () => {
        if (typeof openMonth !== "number") {
            navigate("/");
        } else {
            setOpenMonth(null);
        }
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_services_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setData(json?.Services?.reverse());
                    }
                    setLoad(false);
                });
        }
    }, [load]);

    return (
        <>
            {load ? (
                <>
                    <Navigation />
                    <Loader />
                </>
            ) : typeof openMonth === "object" ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain} />
                    <div className={styles["main"]}>
                        <p className={styles["main_title"]}>Расходы на доп. услуги</p>
                        {data?.length ? (
                            <>
                                <p className={styles["main_sub_title"]}>Выберите месяц</p>
                                <div>
                                    {data?.map((element, index) => (
                                        <Card
                                            key={`card_in_expForAddSer_${index}_${element.Month}`}
                                            icon="calendar_mark_calendar"
                                            text={element.Month.split(" ")[0]}
                                            onClick={() => setOpenMonth(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Notification style={{ marginTop: "16px" }} type="info" icon="info">
                                Расходов нет
                            </Notification>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <ExpensesForMonth prev={prev} data={data && data[openMonth]} />
                </>
            )}
        </>
    );
};

export default ExpensesForAddServices;
