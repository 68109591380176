import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import Change from "../../../../icons/change.svg";
import Delete from "../../../../icons/Close_red.svg";

export interface ICardCheck {
    text?: string;
    price?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDelete?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CardCheck = (props: ICardCheck): ReactElement => {
    const { text, price, onClick = () => null, onDelete = () => null } = props;

    return (
        <div className={styles.card_shell}>
            <div onClick={onClick} className={styles.card}>
                <div className={styles.card_box}>
                    <div className={styles.card_icon}>
                        <Change />
                    </div>
                    <div className={styles.card_box_text}>{text}</div>
                </div>
                <div className={styles.card_box_2}>
                    <div className={styles.card_box_price}>{price}&#8381;</div>
                    <div className={styles.card_icon} />
                </div>
            </div>
            <div className={styles.card_icon_delete} onClick={onDelete}>
                <Delete />
            </div>
        </div>
    );
};

export default CardCheck;
